export const environment = {
  production: false,
  name: 'Development',
  version: '1.3.172',
  projectName: 'Loyale Backoffice',
  baseUrl: 'https://app.dev.loyale.io',
  baseApiUrl: 'https://api.dev.loyale.io',
  setupUrl: 'https://setup.dev.loyale.io',
  intercomAppId: 'npr0rw5i',
  trustIss: 'jwt@loyalecoltd.com',
  trustSiteReference: 'test_loyaleecom126793',
  trustPaymentAddition: '/api/Billing/TPDecodeParentJwt',
  trustBaseAmount: '1050',
  trustLiveStatus: 0,
  recaptchaSiteKey: '6LeeiMAZAAAAALinfToGaPRngg4ArPE3clxdAiPQ',
  recaptchaSecretKey: '6LeeiMAZAAAAAMP0W_o9oSQuWkXTStrLPhlFWaga',
  googleMapsKey: "AIzaSyALshQmnKh_WWmqGBubEabJ8Tzxwuvk7KA",
  subscribeUrl: 'https://web.dev.loyale.io/join',
  storageEncryptKey: "x3muqzQV5JWjWUeLyHznbjheXdL8kiSsyhRJxDVy6yY6C72vUHwW9oCKVwQAPiGH",
  storageDecryptKey: "6C72vUHWUeLyHznbjheXXdL8kiSsy"
};
