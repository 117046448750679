import { Component, OnInit } from '@angular/core';
import { PlanService, SchemeService, ShopifyService } from '../../../swagger';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../../services/common.service';
import { SessionService } from '../../../services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'loyale-subscription-plan-change-dialog',
  templateUrl: './subscription-plan-change-dialog.component.html',
  styleUrls: ['./subscription-plan-change-dialog.component.scss']
})
export class SubscriptionPlanChangeDialogComponent implements OnInit {
  awaitingGet = false;
  awaitingPost = false;
  plans = [];

  selectedPlanId = null;

  usd = false;

  constructor(
    private planService: PlanService,
    private snackBar: MatSnackBar,
    private shopiyService: ShopifyService,
    private dialogRef: MatDialogRef<SubscriptionPlanChangeDialogComponent>,
    public commonService: CommonService,
    public sessionService: SessionService,
    private translate: TranslateService,
    private schemeService: SchemeService) { }

  ngOnInit() {
    this.selectedPlanId = this.sessionService.schemePlan.id;

    if (this.sessionService.schemeOrigin == 1) {
      this.usd = true;
    }

    this.getPlans();
  }

  getPlans() {
    this.awaitingGet = true;

    //get plans is a bit complicated, depending on if the scheme was created after jan 1st 2025, where the new billing system was introduced

    this.schemeService.apiSchemeIdGet(this.sessionService.schemeId).subscribe(res => {
      if (res.isUsingNewBilling) {
        this.planService.apiPlanGet('hidden==false,createdDate>=2025-01-01', 'rank', 1, 100, 'trustpayments').subscribe(res => {
          this.awaitingGet = false;
          this.plans = res;
        });
      } else {
        this.planService.apiPlanGet('hidden==false,createdDate<2025-01-01', 'rank', 1, 100, 'trustpayments').subscribe(res => {
          this.awaitingGet = false;
          this.plans = res;
        });
      }
    })
  }

  onSubmit() {
    if (this.sessionService.schemeOrigin == 0) {
      this.onChangePlan();
    } else if (this.sessionService.schemeOrigin == 1) {
      this.onShopifyChangeSubscription();
    }
  }

  onChangePlan() {
    this.awaitingPost = true;
    this.planService.apiPlanChangePlanPlanIdPost(this.selectedPlanId, this.sessionService.schemeId).subscribe(res => {
      this.awaitingPost = false;

      this.snackBar.open(this.translate.instant('Updated'));
      this.updateScheme();
      this.dialogRef.close(true);
    }, err => {
      this.awaitingPost = false;
      this.commonService.errorHandler(err);
      this.dialogRef.close(false);
    });
  }

  onShopifyChangeSubscription() {
    this.shopiyService.apiShopifyChangeSubscriptionPost({ planId: this.selectedPlanId }).pipe(take(1)).subscribe(res => {
      if (res.redirectUrl != null) {
        this.snackBar.open('Redirecting...');
        window.location.href = res.redirectUrl;
        this.dialogRef.close(true);
      }
    }, err => {
      this.awaitingPost = false;
      this.commonService.errorHandler(err);
      this.dialogRef.close(false);
    });
  }

  updateScheme(): void {
    this.awaitingGet = true;
    this.schemeService.apiSchemeIdGet(this.sessionService.schemeId).pipe(take(1)).subscribe(data => {
      this.sessionService.scheme = data;
      this.awaitingGet = false;
    }, err => {
      this.awaitingPost = false;
      this.commonService.errorHandler(err);
    });
  }
}
