/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { VivaWebhookCreate } from '../model/vivaWebhookCreate';
import { VivaWebhookResultView } from '../model/vivaWebhookResultView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VivaWalletService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVivaWalletCreateWebhookPost(body?: VivaWebhookCreate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVivaWalletCreateWebhookPost(body?: VivaWebhookCreate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVivaWalletCreateWebhookPost(body?: VivaWebhookCreate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVivaWalletCreateWebhookPost(body?: VivaWebhookCreate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/VivaWallet/CreateWebhook`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the status of the Viva Transaction and the error message is failed or CardToken if succeeded
     * 
     * @param vivaTransactionId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVivaWalletGetWebhookDataVivaTransactionIdGet(vivaTransactionId: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<VivaWebhookResultView>;
    public apiVivaWalletGetWebhookDataVivaTransactionIdGet(vivaTransactionId: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VivaWebhookResultView>>;
    public apiVivaWalletGetWebhookDataVivaTransactionIdGet(vivaTransactionId: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VivaWebhookResultView>>;
    public apiVivaWalletGetWebhookDataVivaTransactionIdGet(vivaTransactionId: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (vivaTransactionId === null || vivaTransactionId === undefined) {
            throw new Error('Required parameter vivaTransactionId was null or undefined when calling apiVivaWalletGetWebhookDataVivaTransactionIdGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiVivaWalletGetWebhookDataVivaTransactionIdGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiVivaWalletGetWebhookDataVivaTransactionIdGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VivaWebhookResultView>('get',`${this.basePath}/api/VivaWallet/GetWebhookData/${encodeURIComponent(String(vivaTransactionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVivaWalletGetWebhookKeyGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVivaWalletGetWebhookKeyGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVivaWalletGetWebhookKeyGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVivaWalletGetWebhookKeyGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/VivaWallet/GetWebhookKey`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Partially refunds a transaction
     * 
     * @param vivaTransactionId 
     * @param xApiKey 
     * @param xScheme 
     * @param loyaleUniqueTransactionReference 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVivaWalletRefundTransactionVivaTransactionIdPost(vivaTransactionId: string, xApiKey: string, xScheme: string, loyaleUniqueTransactionReference?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVivaWalletRefundTransactionVivaTransactionIdPost(vivaTransactionId: string, xApiKey: string, xScheme: string, loyaleUniqueTransactionReference?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVivaWalletRefundTransactionVivaTransactionIdPost(vivaTransactionId: string, xApiKey: string, xScheme: string, loyaleUniqueTransactionReference?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVivaWalletRefundTransactionVivaTransactionIdPost(vivaTransactionId: string, xApiKey: string, xScheme: string, loyaleUniqueTransactionReference?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (vivaTransactionId === null || vivaTransactionId === undefined) {
            throw new Error('Required parameter vivaTransactionId was null or undefined when calling apiVivaWalletRefundTransactionVivaTransactionIdPost.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiVivaWalletRefundTransactionVivaTransactionIdPost.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiVivaWalletRefundTransactionVivaTransactionIdPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loyaleUniqueTransactionReference !== undefined && loyaleUniqueTransactionReference !== null) {
            queryParameters = queryParameters.set('loyaleUniqueTransactionReference', <any>loyaleUniqueTransactionReference);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/VivaWallet/RefundTransaction/${encodeURIComponent(String(vivaTransactionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVivaWalletWebhookGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVivaWalletWebhookGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVivaWalletWebhookGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVivaWalletWebhookGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/VivaWallet/Webhook`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVivaWalletWebhookPost(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVivaWalletWebhookPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVivaWalletWebhookPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVivaWalletWebhookPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/VivaWallet/Webhook`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
