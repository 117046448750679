/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CouponsLinkedResultView } from '../model/couponsLinkedResultView';
import { GiftCardLinkedFavoriteView } from '../model/giftCardLinkedFavoriteView';
import { GiftCardLinkedGiftDetailsPutView } from '../model/giftCardLinkedGiftDetailsPutView';
import { GiftCardLinkedInsertView } from '../model/giftCardLinkedInsertView';
import { GiftCardLinkedLinkingView } from '../model/giftCardLinkedLinkingView';
import { GiftCardLinkedPutView } from '../model/giftCardLinkedPutView';
import { GiftCardLinkedResultView } from '../model/giftCardLinkedResultView';
import { GiftCardLinkedUseView } from '../model/giftCardLinkedUseView';
import { GiftCardUsageReportView } from '../model/giftCardUsageReportView';
import { GiftCardsLinked } from '../model/giftCardsLinked';
import { LinkedItemsCountResultView } from '../model/linkedItemsCountResultView';
import { UseGiftCardLinkedView } from '../model/useGiftCardLinkedView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GiftCardsLinkedService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieves list of Customer Giftcards
     * 
     * @param profileId 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GiftCardLinkedResultView>>>;
    public apiGiftCardsLinkedByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GiftCardLinkedResultView>>>;
    public apiGiftCardsLinkedByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiGiftCardsLinkedByProfileIdProfileIdGet.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GiftCardLinkedResultView>>('get',`${this.basePath}/api/GiftCardsLinked/byProfileId/${encodeURIComponent(String(profileId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the Customer GiftCard
     * 
     * @param giftCardLinkedId 
     * @param profileId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedByProfileIdProfileIdGiftCardLinkedIdGet(giftCardLinkedId: string, profileId: string, xApiKey?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardLinkedResultView>;
    public apiGiftCardsLinkedByProfileIdProfileIdGiftCardLinkedIdGet(giftCardLinkedId: string, profileId: string, xApiKey?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedByProfileIdProfileIdGiftCardLinkedIdGet(giftCardLinkedId: string, profileId: string, xApiKey?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedByProfileIdProfileIdGiftCardLinkedIdGet(giftCardLinkedId: string, profileId: string, xApiKey?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftCardLinkedId === null || giftCardLinkedId === undefined) {
            throw new Error('Required parameter giftCardLinkedId was null or undefined when calling apiGiftCardsLinkedByProfileIdProfileIdGiftCardLinkedIdGet.');
        }

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiGiftCardsLinkedByProfileIdProfileIdGiftCardLinkedIdGet.');
        }



        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GiftCardLinkedResultView>('get',`${this.basePath}/api/GiftCardsLinked/byProfileId/${encodeURIComponent(String(profileId))}/${encodeURIComponent(String(giftCardLinkedId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a count of linked GiftCards
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedCountGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<LinkedItemsCountResultView>;
    public apiGiftCardsLinkedCountGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LinkedItemsCountResultView>>;
    public apiGiftCardsLinkedCountGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LinkedItemsCountResultView>>;
    public apiGiftCardsLinkedCountGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LinkedItemsCountResultView>('get',`${this.basePath}/api/GiftCardsLinked/Count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of used GiftCards for the given daterange and schemeId
     * 
     * @param dateFrom 
     * @param dateTo 
     * @param giftCardId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedDailyUsageGet(dateFrom?: Date, dateTo?: Date, giftCardId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiGiftCardsLinkedDailyUsageGet(dateFrom?: Date, dateTo?: Date, giftCardId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiGiftCardsLinkedDailyUsageGet(dateFrom?: Date, dateTo?: Date, giftCardId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiGiftCardsLinkedDailyUsageGet(dateFrom?: Date, dateTo?: Date, giftCardId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('dateFrom', <any>dateFrom.toISOString());
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('dateTo', <any>dateTo.toISOString());
        }
        if (giftCardId !== undefined && giftCardId !== null) {
            queryParameters = queryParameters.set('giftCardId', <any>giftCardId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/GiftCardsLinked/DailyUsage`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheme 
     * @param dateFrom 
     * @param dateTo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedEnabledGet(scheme?: string, dateFrom?: Date, dateTo?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiGiftCardsLinkedEnabledGet(scheme?: string, dateFrom?: Date, dateTo?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiGiftCardsLinkedEnabledGet(scheme?: string, dateFrom?: Date, dateTo?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiGiftCardsLinkedEnabledGet(scheme?: string, dateFrom?: Date, dateTo?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('dateFrom', <any>dateFrom.toISOString());
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('dateTo', <any>dateTo.toISOString());
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/GiftCardsLinked/Enabled`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export GiftCardsLinked by email.
     * 
     * @param body 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param giftCardId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiGiftCardsLinkedExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiGiftCardsLinkedExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiGiftCardsLinkedExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (giftCardId !== undefined && giftCardId !== null) {
            queryParameters = queryParameters.set('giftCardId', <any>giftCardId);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/GiftCardsLinked/Export`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Exports the filtered GiftCardsLinked as QRCodes
     * 
     * @param body 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedExportQRCodesPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiGiftCardsLinkedExportQRCodesPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiGiftCardsLinkedExportQRCodesPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiGiftCardsLinkedExportQRCodesPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/GiftCardsLinked/ExportQRCodes`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a List of GiftCardsLinked, depending on the Filter or Sorting
     * 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GiftCardLinkedResultView>>>;
    public apiGiftCardsLinkedGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GiftCardLinkedResultView>>>;
    public apiGiftCardsLinkedGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiGiftCardsLinkedGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GiftCardLinkedResultView>>('get',`${this.basePath}/api/GiftCardsLinked`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a GiftCard by passing the Id
     * 
     * @param giftCardLinkedId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedGiftCardLinkedIdDelete(giftCardLinkedId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiGiftCardsLinkedGiftCardLinkedIdDelete(giftCardLinkedId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiGiftCardsLinkedGiftCardLinkedIdDelete(giftCardLinkedId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiGiftCardsLinkedGiftCardLinkedIdDelete(giftCardLinkedId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftCardLinkedId === null || giftCardLinkedId === undefined) {
            throw new Error('Required parameter giftCardLinkedId was null or undefined when calling apiGiftCardsLinkedGiftCardLinkedIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/GiftCardsLinked/${encodeURIComponent(String(giftCardLinkedId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a GiftCardLinked by id.
     * 
     * @param giftCardLinkedId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardLinkedResultView>;
    public apiGiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftCardLinkedId === null || giftCardLinkedId === undefined) {
            throw new Error('Required parameter giftCardLinkedId was null or undefined when calling apiGiftCardsLinkedGiftCardLinkedIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GiftCardLinkedResultView>('get',`${this.basePath}/api/GiftCardsLinked/${encodeURIComponent(String(giftCardLinkedId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the GiftCardLinked object
     * 
     * @param giftCardLinkedId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedGiftDetailsGiftCardLinkedIdPut(giftCardLinkedId: string, body?: GiftCardLinkedGiftDetailsPutView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardLinkedResultView>;
    public apiGiftCardsLinkedGiftDetailsGiftCardLinkedIdPut(giftCardLinkedId: string, body?: GiftCardLinkedGiftDetailsPutView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedGiftDetailsGiftCardLinkedIdPut(giftCardLinkedId: string, body?: GiftCardLinkedGiftDetailsPutView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedGiftDetailsGiftCardLinkedIdPut(giftCardLinkedId: string, body?: GiftCardLinkedGiftDetailsPutView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftCardLinkedId === null || giftCardLinkedId === undefined) {
            throw new Error('Required parameter giftCardLinkedId was null or undefined when calling apiGiftCardsLinkedGiftDetailsGiftCardLinkedIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GiftCardLinkedResultView>('put',`${this.basePath}/api/GiftCardsLinked/GiftDetails/${encodeURIComponent(String(giftCardLinkedId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Link a Customer to a GiftCardsLinked.
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedLinkPost(body?: GiftCardLinkedLinkingView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardLinkedResultView>;
    public apiGiftCardsLinkedLinkPost(body?: GiftCardLinkedLinkingView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedLinkPost(body?: GiftCardLinkedLinkingView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedLinkPost(body?: GiftCardLinkedLinkingView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GiftCardLinkedResultView>('post',`${this.basePath}/api/GiftCardsLinked/Link`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate linked GiftCards.
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedPost(body?: GiftCardLinkedInsertView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardLinkedResultView>;
    public apiGiftCardsLinkedPost(body?: GiftCardLinkedInsertView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedPost(body?: GiftCardLinkedInsertView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedPost(body?: GiftCardLinkedInsertView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GiftCardLinkedResultView>('post',`${this.basePath}/api/GiftCardsLinked`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edit a GiftCardsLinked.
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedPut(body?: GiftCardLinkedPutView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardLinkedResultView>;
    public apiGiftCardsLinkedPut(body?: GiftCardLinkedPutView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedPut(body?: GiftCardLinkedPutView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardLinkedResultView>>;
    public apiGiftCardsLinkedPut(body?: GiftCardLinkedPutView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GiftCardLinkedResultView>('put',`${this.basePath}/api/GiftCardsLinked`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets all gift Card that are shared by Post
     * 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedShareByPostGet(xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiGiftCardsLinkedShareByPostGet(xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiGiftCardsLinkedShareByPostGet(xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiGiftCardsLinkedShareByPostGet(xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/GiftCardsLinked/ShareByPost`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export of GiftCardsUsage sent by email.
     * 
     * @param body 
     * @param scheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param giftCardId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedUsageExportPost(body?: Array<string>, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiGiftCardsLinkedUsageExportPost(body?: Array<string>, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiGiftCardsLinkedUsageExportPost(body?: Array<string>, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiGiftCardsLinkedUsageExportPost(body?: Array<string>, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (giftCardId !== undefined && giftCardId !== null) {
            queryParameters = queryParameters.set('giftCardId', <any>giftCardId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/GiftCardsLinked/Usage/Export`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of used GiftCards for the given daterange and schemeId
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param giftCardId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedUsageGet(filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiGiftCardsLinkedUsageGet(filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiGiftCardsLinkedUsageGet(filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiGiftCardsLinkedUsageGet(filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (giftCardId !== undefined && giftCardId !== null) {
            queryParameters = queryParameters.set('giftCardId', <any>giftCardId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/GiftCardsLinked/Usage`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate linked GiftCards.
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiGiftCardsLinkedUseGiftCardPost(body?: GiftCardLinkedUseView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardsLinked>;
    public apiGiftCardsLinkedUseGiftCardPost(body?: GiftCardLinkedUseView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardsLinked>>;
    public apiGiftCardsLinkedUseGiftCardPost(body?: GiftCardLinkedUseView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardsLinked>>;
    public apiGiftCardsLinkedUseGiftCardPost(body?: GiftCardLinkedUseView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GiftCardsLinked>('post',`${this.basePath}/api/GiftCardsLinked/UseGiftCard`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Gift Card Linked by Barcode
     * This endpoint returns one gift card linked by the barcode.
     * @param giftCardLinkedBarCode 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosGiftCardsLinkedByBarcodeGiftCardLinkedBarCodeGet(giftCardLinkedBarCode: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardLinkedResultView>;
    public apiPosGiftCardsLinkedByBarcodeGiftCardLinkedBarCodeGet(giftCardLinkedBarCode: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardLinkedResultView>>;
    public apiPosGiftCardsLinkedByBarcodeGiftCardLinkedBarCodeGet(giftCardLinkedBarCode: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardLinkedResultView>>;
    public apiPosGiftCardsLinkedByBarcodeGiftCardLinkedBarCodeGet(giftCardLinkedBarCode: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftCardLinkedBarCode === null || giftCardLinkedBarCode === undefined) {
            throw new Error('Required parameter giftCardLinkedBarCode was null or undefined when calling apiPosGiftCardsLinkedByBarcodeGiftCardLinkedBarCodeGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosGiftCardsLinkedByBarcodeGiftCardLinkedBarCodeGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosGiftCardsLinkedByBarcodeGiftCardLinkedBarCodeGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GiftCardLinkedResultView>('get',`${this.basePath}/api/Pos/GiftCardsLinked/ByBarcode/${encodeURIComponent(String(giftCardLinkedBarCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Gift Card Linked
     * This endpoint returns one gift card linked.
     * @param xApiKey 
     * @param xScheme 
     * @param barCode 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosGiftCardsLinkedByIdentifierGet(xApiKey: string, xScheme: string, barCode?: string, id?: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardLinkedResultView>;
    public apiPosGiftCardsLinkedByIdentifierGet(xApiKey: string, xScheme: string, barCode?: string, id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardLinkedResultView>>;
    public apiPosGiftCardsLinkedByIdentifierGet(xApiKey: string, xScheme: string, barCode?: string, id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardLinkedResultView>>;
    public apiPosGiftCardsLinkedByIdentifierGet(xApiKey: string, xScheme: string, barCode?: string, id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosGiftCardsLinkedByIdentifierGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosGiftCardsLinkedByIdentifierGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (barCode !== undefined && barCode !== null) {
            queryParameters = queryParameters.set('barCode', <any>barCode);
        }
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GiftCardLinkedResultView>('get',`${this.basePath}/api/Pos/GiftCardsLinked/ByIdentifier`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Gift Cards Linked
     * This endpoint returns a list of gift cards linked to the customer. These can be filtered, sorted and paged by the query parameters passed.
     * @param xApiKey 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosGiftCardsLinkedGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<GiftCardLinkedResultView>>;
    public apiPosGiftCardsLinkedGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GiftCardLinkedResultView>>>;
    public apiPosGiftCardsLinkedGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GiftCardLinkedResultView>>>;
    public apiPosGiftCardsLinkedGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosGiftCardsLinkedGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosGiftCardsLinkedGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GiftCardLinkedResultView>>('get',`${this.basePath}/api/Pos/GiftCardsLinked`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Gift Card Linked by ID
     * This endpoint returns one gift card linked by the id.
     * @param giftCardLinkedId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosGiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardLinkedResultView>;
    public apiPosGiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardLinkedResultView>>;
    public apiPosGiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardLinkedResultView>>;
    public apiPosGiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftCardLinkedId === null || giftCardLinkedId === undefined) {
            throw new Error('Required parameter giftCardLinkedId was null or undefined when calling apiPosGiftCardsLinkedGiftCardLinkedIdGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosGiftCardsLinkedGiftCardLinkedIdGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosGiftCardsLinkedGiftCardLinkedIdGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GiftCardLinkedResultView>('get',`${this.basePath}/api/Pos/GiftCardsLinked/${encodeURIComponent(String(giftCardLinkedId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get List of Gift Cards Linked
     * This endpoint returns a list of gift cards linked to the customer. These can be filtered, sorted and paged by the query parameters passed.
     * @param xApiKey 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosGiftCardsLinkedListGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<GiftCardLinkedResultView>>;
    public apiPosGiftCardsLinkedListGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GiftCardLinkedResultView>>>;
    public apiPosGiftCardsLinkedListGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GiftCardLinkedResultView>>>;
    public apiPosGiftCardsLinkedListGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosGiftCardsLinkedListGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosGiftCardsLinkedListGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GiftCardLinkedResultView>>('get',`${this.basePath}/api/Pos/GiftCardsLinked/List`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Use a Gift Card Linked
     * 
     * @param xApiKey 
     * @param body 
     * @param xScheme 
     * @param barCode 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosGiftCardsLinkedUsePost(xApiKey: string, body?: UseGiftCardLinkedView, xScheme?: string, barCode?: string, id?: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardLinkedResultView>;
    public apiPosGiftCardsLinkedUsePost(xApiKey: string, body?: UseGiftCardLinkedView, xScheme?: string, barCode?: string, id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardLinkedResultView>>;
    public apiPosGiftCardsLinkedUsePost(xApiKey: string, body?: UseGiftCardLinkedView, xScheme?: string, barCode?: string, id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardLinkedResultView>>;
    public apiPosGiftCardsLinkedUsePost(xApiKey: string, body?: UseGiftCardLinkedView, xScheme?: string, barCode?: string, id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosGiftCardsLinkedUsePost.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (barCode !== undefined && barCode !== null) {
            queryParameters = queryParameters.set('barCode', <any>barCode);
        }
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GiftCardLinkedResultView>('post',`${this.basePath}/api/Pos/GiftCardsLinked/Use`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the Customer coupon
     * 
     * @param giftCardLinkedId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2GiftCardsLinkedFavoriteGiftCardLinkedIdPut(giftCardLinkedId: string, body?: GiftCardLinkedFavoriteView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiV2GiftCardsLinkedFavoriteGiftCardLinkedIdPut(giftCardLinkedId: string, body?: GiftCardLinkedFavoriteView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiV2GiftCardsLinkedFavoriteGiftCardLinkedIdPut(giftCardLinkedId: string, body?: GiftCardLinkedFavoriteView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiV2GiftCardsLinkedFavoriteGiftCardLinkedIdPut(giftCardLinkedId: string, body?: GiftCardLinkedFavoriteView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftCardLinkedId === null || giftCardLinkedId === undefined) {
            throw new Error('Required parameter giftCardLinkedId was null or undefined when calling apiV2GiftCardsLinkedFavoriteGiftCardLinkedIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('put',`${this.basePath}/api/v2/GiftCardsLinked/Favorite/${encodeURIComponent(String(giftCardLinkedId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Gift Cards Linked
     * This endpoint returns a list of gift cards linked to the customer. These can be filtered, sorted and paged by the query parameters passed.
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2GiftCardsLinkedGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<GiftCardLinkedResultView>>;
    public apiV2GiftCardsLinkedGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GiftCardLinkedResultView>>>;
    public apiV2GiftCardsLinkedGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GiftCardLinkedResultView>>>;
    public apiV2GiftCardsLinkedGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiV2GiftCardsLinkedGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GiftCardLinkedResultView>>('get',`${this.basePath}/api/v2/GiftCardsLinked`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Gift Card Linked by ID
     * This endpoint returns one gift card linked by the id.
     * @param giftCardLinkedId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2GiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<GiftCardLinkedResultView>;
    public apiV2GiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GiftCardLinkedResultView>>;
    public apiV2GiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GiftCardLinkedResultView>>;
    public apiV2GiftCardsLinkedGiftCardLinkedIdGet(giftCardLinkedId: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftCardLinkedId === null || giftCardLinkedId === undefined) {
            throw new Error('Required parameter giftCardLinkedId was null or undefined when calling apiV2GiftCardsLinkedGiftCardLinkedIdGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiV2GiftCardsLinkedGiftCardLinkedIdGet.');
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GiftCardLinkedResultView>('get',`${this.basePath}/api/v2/GiftCardsLinked/${encodeURIComponent(String(giftCardLinkedId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Gift Card Usage
     * This endpoint returns a list of gift cards linked that were used by the customer. These can be filtered, sorted and paged by the query parameters passed.
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param giftCardId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2GiftCardsLinkedUsageGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GiftCardUsageReportView>>;
    public apiV2GiftCardsLinkedUsageGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GiftCardUsageReportView>>>;
    public apiV2GiftCardsLinkedUsageGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GiftCardUsageReportView>>>;
    public apiV2GiftCardsLinkedUsageGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, giftCardId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiV2GiftCardsLinkedUsageGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (giftCardId !== undefined && giftCardId !== null) {
            queryParameters = queryParameters.set('giftCardId', <any>giftCardId);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GiftCardUsageReportView>>('get',`${this.basePath}/api/v2/GiftCardsLinked/Usage`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
