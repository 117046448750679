/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AccountCompletionView } from '../model/accountCompletionView';
import { AssignExistingRequestView } from '../model/assignExistingRequestView';
import { BarcodeChangeView } from '../model/barcodeChangeView';
import { BasicCustomerInsertView } from '../model/basicCustomerInsertView';
import { BasicSchemeResultViewWithCustomerPolicyCheck } from '../model/basicSchemeResultViewWithCustomerPolicyCheck';
import { CardToken } from '../model/cardToken';
import { CardTokenInsertView } from '../model/cardTokenInsertView';
import { CustomerInsertView } from '../model/customerInsertView';
import { CustomerInsertViewV2 } from '../model/customerInsertViewV2';
import { CustomerOffersResultView } from '../model/customerOffersResultView';
import { CustomerProfileInsertView } from '../model/customerProfileInsertView';
import { CustomerPutView } from '../model/customerPutView';
import { CustomerPutViewV2 } from '../model/customerPutViewV2';
import { CustomerResultView } from '../model/customerResultView';
import { CustomerResultViewPos } from '../model/customerResultViewPos';
import { CustomerStatementResultView } from '../model/customerStatementResultView';
import { CustomerWithAdditionalFieldsInsertView } from '../model/customerWithAdditionalFieldsInsertView';
import { CustomerWithAdditionalFieldsPutView } from '../model/customerWithAdditionalFieldsPutView';
import { CustomerWithAdditionalFieldsResultView } from '../model/customerWithAdditionalFieldsResultView';
import { FamilyLink } from '../model/familyLink';
import { FamilyLinkIdResultView } from '../model/familyLinkIdResultView';
import { FamilyLinkInsertView } from '../model/familyLinkInsertView';
import { FamilyLinkResultView } from '../model/familyLinkResultView';
import { IsAdditionalFieldUniqueResultView } from '../model/isAdditionalFieldUniqueResultView';
import { Item } from '../model/item';
import { JoinSchemeInsertView } from '../model/joinSchemeInsertView';
import { ListCompareType } from '../model/listCompareType';
import { MemberBalanceResultView } from '../model/memberBalanceResultView';
import { PasswordView } from '../model/passwordView';
import { PointBalanceSummaryResult } from '../model/pointBalanceSummaryResult';
import { ProgressGroupedByTier } from '../model/progressGroupedByTier';
import { UpdateGuestAccountView } from '../model/updateGuestAccountView';
import { VerifyCustomerTokenRequestView } from '../model/verifyCustomerTokenRequestView';
import { WalletCustomerInsertView } from '../model/walletCustomerInsertView';
import { WalletCustomerPutView } from '../model/walletCustomerPutView';
import { WalletCustomerResultView } from '../model/walletCustomerResultView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CustomerService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Reactivate a suspended customer.
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerActivateIdPut(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerActivateIdPut(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerActivateIdPut(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerActivateIdPut(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiCustomerActivateIdPut.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/Customer/Activate/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerActivityCustomerIdGet(customerId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerActivityCustomerIdGet(customerId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerActivityCustomerIdGet(customerId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerActivityCustomerIdGet(customerId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiCustomerActivityCustomerIdGet.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiCustomerActivityCustomerIdGet.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/activity/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a Level to a Customer
     * 
     * @param customerId 
     * @param levelId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerAddLevelPost(customerId?: string, levelId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerAddLevelPost(customerId?: string, levelId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerAddLevelPost(customerId?: string, levelId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerAddLevelPost(customerId?: string, levelId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }
        if (levelId !== undefined && levelId !== null) {
            queryParameters = queryParameters.set('levelId', <any>levelId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/AddLevel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Customers Balance by profileId using Profile auth
     * 
     * @param profileId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerBalanceByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<PointBalanceSummaryResult>;
    public apiCustomerBalanceByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PointBalanceSummaryResult>>;
    public apiCustomerBalanceByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PointBalanceSummaryResult>>;
    public apiCustomerBalanceByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiCustomerBalanceByProfileIdProfileIdGet.');
        }



        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PointBalanceSummaryResult>('get',`${this.basePath}/api/Customer/Balance/byProfileId/${encodeURIComponent(String(profileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Customers Balance by customerId
     * 
     * @param customerId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerBalanceCustomerIdGet(customerId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<PointBalanceSummaryResult>;
    public apiCustomerBalanceCustomerIdGet(customerId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PointBalanceSummaryResult>>;
    public apiCustomerBalanceCustomerIdGet(customerId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PointBalanceSummaryResult>>;
    public apiCustomerBalanceCustomerIdGet(customerId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiCustomerBalanceCustomerIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PointBalanceSummaryResult>('get',`${this.basePath}/api/Customer/Balance/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to get barcode history for customer
     * 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerBarcodeHistoryGet(customerId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerBarcodeHistoryGet(customerId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerBarcodeHistoryGet(customerId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerBarcodeHistoryGet(customerId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/BarcodeHistory`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param file 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerBatchImportPostForm(file?: Blob, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerBatchImportPostForm(file?: Blob, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerBatchImportPostForm(file?: Blob, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerBatchImportPostForm(file?: Blob, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/BatchImport`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param file 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerBatchUpdatePutForm(file?: Blob, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerBatchUpdatePutForm(file?: Blob, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerBatchUpdatePutForm(file?: Blob, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerBatchUpdatePutForm(file?: Blob, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Customer/BatchUpdate`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Customer by id using Profile auth
     * 
     * @param profileId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiCustomerByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiCustomerByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiCustomerByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiCustomerByProfileIdProfileIdGet.');
        }



        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerResultView>('get',`${this.basePath}/api/Customer/byProfileId/${encodeURIComponent(String(profileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify a Customer using Profile auth
     * 
     * @param profileId 
     * @param body 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerByProfileIdProfileIdPut(profileId: string, body?: CustomerPutView, xApiKey?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerByProfileIdProfileIdPut(profileId: string, body?: CustomerPutView, xApiKey?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerByProfileIdProfileIdPut(profileId: string, body?: CustomerPutView, xApiKey?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerByProfileIdProfileIdPut(profileId: string, body?: CustomerPutView, xApiKey?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiCustomerByProfileIdProfileIdPut.');
        }




        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Customer/byProfileId/${encodeURIComponent(String(profileId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for customers to close their account
     * 
     * @param customerId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiCustomerCloseAccountCustomerIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/CloseAccount/${encodeURIComponent(String(customerId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * CreateOnPos
     * 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerCreateOnPosGet(customerId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerCreateOnPosGet(customerId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerCreateOnPosGet(customerId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerCreateOnPosGet(customerId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/CreateOnPos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a profile and link to this customer account.
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerCreateProfileSchemeIntegrationIdPost(schemeIntegrationId: string, body?: CustomerProfileInsertView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerCreateProfileSchemeIntegrationIdPost(schemeIntegrationId: string, body?: CustomerProfileInsertView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerCreateProfileSchemeIntegrationIdPost(schemeIntegrationId: string, body?: CustomerProfileInsertView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerCreateProfileSchemeIntegrationIdPost(schemeIntegrationId: string, body?: CustomerProfileInsertView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiCustomerCreateProfileSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/CreateProfile/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customer using their card token
     * This endpoint returns a customer.
     * @param cardToken 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerCustomerByCardTokenCardTokenGet(cardToken: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiCustomerCustomerByCardTokenCardTokenGet(cardToken: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiCustomerCustomerByCardTokenCardTokenGet(cardToken: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiCustomerCustomerByCardTokenCardTokenGet(cardToken: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cardToken === null || cardToken === undefined) {
            throw new Error('Required parameter cardToken was null or undefined when calling apiCustomerCustomerByCardTokenCardTokenGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiCustomerCustomerByCardTokenCardTokenGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiCustomerCustomerByCardTokenCardTokenGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerResultView>('get',`${this.basePath}/api/Customer/CustomerByCardToken/${encodeURIComponent(String(cardToken))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set Customer MarketingSub to false
     * 
     * @param email 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerEmailUnsubscribePut(email?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerEmailUnsubscribePut(email?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerEmailUnsubscribePut(email?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerEmailUnsubscribePut(email?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/Customer/EmailUnsubscribe`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates customer csv report with filtering
     * 
     * @param xScheme 
     * @param body 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param pointBalanceOperator 
     * @param pointBalanceValue 
     * @param tagOperator 
     * @param tagIds 
     * @param levelOperator 
     * @param levelIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerExportPost(xScheme: string, body?: Array<string>, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerExportPost(xScheme: string, body?: Array<string>, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerExportPost(xScheme: string, body?: Array<string>, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerExportPost(xScheme: string, body?: Array<string>, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiCustomerExportPost.');
        }












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (pointBalanceOperator !== undefined && pointBalanceOperator !== null) {
            queryParameters = queryParameters.set('PointBalanceOperator', <any>pointBalanceOperator);
        }
        if (pointBalanceValue !== undefined && pointBalanceValue !== null) {
            queryParameters = queryParameters.set('PointBalanceValue', <any>pointBalanceValue);
        }
        if (tagOperator !== undefined && tagOperator !== null) {
            queryParameters = queryParameters.set('TagOperator', <any>tagOperator);
        }
        if (tagIds) {
            tagIds.forEach((element) => {
                queryParameters = queryParameters.append('TagIds', <any>element);
            })
        }
        if (levelOperator !== undefined && levelOperator !== null) {
            queryParameters = queryParameters.set('LevelOperator', <any>levelOperator);
        }
        if (levelIds) {
            levelIds.forEach((element) => {
                queryParameters = queryParameters.append('LevelIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/Export`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a family link
     * 
     * @param familyLinkId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerFamilyLinkFamilyLinkIdDelete(familyLinkId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerFamilyLinkFamilyLinkIdDelete(familyLinkId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerFamilyLinkFamilyLinkIdDelete(familyLinkId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerFamilyLinkFamilyLinkIdDelete(familyLinkId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (familyLinkId === null || familyLinkId === undefined) {
            throw new Error('Required parameter familyLinkId was null or undefined when calling apiCustomerFamilyLinkFamilyLinkIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Customer/FamilyLink/${encodeURIComponent(String(familyLinkId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Edit a family link relationship.
     * 
     * @param familyLinkId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerFamilyLinkFamilyLinkIdPut(familyLinkId: string, body?: FamilyLink, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<FamilyLink>;
    public apiCustomerFamilyLinkFamilyLinkIdPut(familyLinkId: string, body?: FamilyLink, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FamilyLink>>;
    public apiCustomerFamilyLinkFamilyLinkIdPut(familyLinkId: string, body?: FamilyLink, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FamilyLink>>;
    public apiCustomerFamilyLinkFamilyLinkIdPut(familyLinkId: string, body?: FamilyLink, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (familyLinkId === null || familyLinkId === undefined) {
            throw new Error('Required parameter familyLinkId was null or undefined when calling apiCustomerFamilyLinkFamilyLinkIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<FamilyLink>('put',`${this.basePath}/api/Customer/FamilyLink/${encodeURIComponent(String(familyLinkId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the family link id relating to the provided parentId and childId.
     * 
     * @param parentId 
     * @param childId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerFamilyLinkParentIdChildIdGet(parentId: string, childId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<FamilyLinkIdResultView>;
    public apiCustomerFamilyLinkParentIdChildIdGet(parentId: string, childId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FamilyLinkIdResultView>>;
    public apiCustomerFamilyLinkParentIdChildIdGet(parentId: string, childId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FamilyLinkIdResultView>>;
    public apiCustomerFamilyLinkParentIdChildIdGet(parentId: string, childId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parentId === null || parentId === undefined) {
            throw new Error('Required parameter parentId was null or undefined when calling apiCustomerFamilyLinkParentIdChildIdGet.');
        }

        if (childId === null || childId === undefined) {
            throw new Error('Required parameter childId was null or undefined when calling apiCustomerFamilyLinkParentIdChildIdGet.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiCustomerFamilyLinkParentIdChildIdGet.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FamilyLinkIdResultView>('get',`${this.basePath}/api/Customer/FamilyLink/${encodeURIComponent(String(parentId))}/${encodeURIComponent(String(childId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of customers that are children of the provided parentId
     * 
     * @param parentId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerFamilyLinkParentIdGet(parentId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FamilyLinkResultView>>;
    public apiCustomerFamilyLinkParentIdGet(parentId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FamilyLinkResultView>>>;
    public apiCustomerFamilyLinkParentIdGet(parentId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FamilyLinkResultView>>>;
    public apiCustomerFamilyLinkParentIdGet(parentId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parentId === null || parentId === undefined) {
            throw new Error('Required parameter parentId was null or undefined when calling apiCustomerFamilyLinkParentIdGet.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiCustomerFamilyLinkParentIdGet.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FamilyLinkResultView>>('get',`${this.basePath}/api/Customer/FamilyLink/${encodeURIComponent(String(parentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new family link between a parent customer and child customer.
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerFamilyLinkPost(body?: FamilyLinkInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<FamilyLink>;
    public apiCustomerFamilyLinkPost(body?: FamilyLinkInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FamilyLink>>;
    public apiCustomerFamilyLinkPost(body?: FamilyLinkInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FamilyLink>>;
    public apiCustomerFamilyLinkPost(body?: FamilyLinkInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<FamilyLink>('post',`${this.basePath}/api/Customer/FamilyLink`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if an email is already in use.
     * 
     * @param customerEmail 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerFindEmailGet(customerEmail?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerFindEmailGet(customerEmail?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerFindEmailGet(customerEmail?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerFindEmailGet(customerEmail?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerEmail !== undefined && customerEmail !== null) {
            queryParameters = queryParameters.set('customerEmail', <any>customerEmail);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/FindEmail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a List of Customer, depending on the Filter or Sorting
     * 
     * @param scheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param pointBalanceOperator 
     * @param pointBalanceValue 
     * @param tagOperator 
     * @param tagIds 
     * @param levelOperator 
     * @param levelIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerResultView>>;
    public apiCustomerGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerResultView>>>;
    public apiCustomerGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerResultView>>>;
    public apiCustomerGet(scheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiCustomerGet.');
        }











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (pointBalanceOperator !== undefined && pointBalanceOperator !== null) {
            queryParameters = queryParameters.set('PointBalanceOperator', <any>pointBalanceOperator);
        }
        if (pointBalanceValue !== undefined && pointBalanceValue !== null) {
            queryParameters = queryParameters.set('PointBalanceValue', <any>pointBalanceValue);
        }
        if (tagOperator !== undefined && tagOperator !== null) {
            queryParameters = queryParameters.set('TagOperator', <any>tagOperator);
        }
        if (tagIds) {
            tagIds.forEach((element) => {
                queryParameters = queryParameters.append('TagIds', <any>element);
            })
        }
        if (levelOperator !== undefined && levelOperator !== null) {
            queryParameters = queryParameters.set('LevelOperator', <any>levelOperator);
        }
        if (levelIds) {
            levelIds.forEach((element) => {
                queryParameters = queryParameters.append('LevelIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CustomerResultView>>('get',`${this.basePath}/api/Customer`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the Customer with his additional details for this Scheme
     * 
     * @param scheme 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerGetAdditionalGet(scheme: string, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerWithAdditionalFieldsResultView>;
    public apiCustomerGetAdditionalGet(scheme: string, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerWithAdditionalFieldsResultView>>;
    public apiCustomerGetAdditionalGet(scheme: string, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerWithAdditionalFieldsResultView>>;
    public apiCustomerGetAdditionalGet(scheme: string, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiCustomerGetAdditionalGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerWithAdditionalFieldsResultView>('get',`${this.basePath}/api/Customer/GetAdditional`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Address of a Customer if it exists in AdditionalFields
     * 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerGetAddressGet(customerId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerGetAddressGet(customerId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerGetAddressGet(customerId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerGetAddressGet(customerId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/GetAddress`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a List of Customers with Additional data, depending on the Filter or Sorting
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param memberCode 
     * @param barcode 
     * @param lastName 
     * @param firstName 
     * @param email 
     * @param idCard 
     * @param mobile 
     * @param phone 
     * @param town 
     * @param addressLine1 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerGetAllByAdditionalSeparatedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, memberCode?: string, barcode?: string, lastName?: string, firstName?: string, email?: string, idCard?: string, mobile?: string, phone?: string, town?: string, addressLine1?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerResultView>>;
    public apiCustomerGetAllByAdditionalSeparatedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, memberCode?: string, barcode?: string, lastName?: string, firstName?: string, email?: string, idCard?: string, mobile?: string, phone?: string, town?: string, addressLine1?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerResultView>>>;
    public apiCustomerGetAllByAdditionalSeparatedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, memberCode?: string, barcode?: string, lastName?: string, firstName?: string, email?: string, idCard?: string, mobile?: string, phone?: string, town?: string, addressLine1?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerResultView>>>;
    public apiCustomerGetAllByAdditionalSeparatedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, memberCode?: string, barcode?: string, lastName?: string, firstName?: string, email?: string, idCard?: string, mobile?: string, phone?: string, town?: string, addressLine1?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (memberCode !== undefined && memberCode !== null) {
            queryParameters = queryParameters.set('memberCode', <any>memberCode);
        }
        if (barcode !== undefined && barcode !== null) {
            queryParameters = queryParameters.set('barcode', <any>barcode);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any>firstName);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (idCard !== undefined && idCard !== null) {
            queryParameters = queryParameters.set('idCard', <any>idCard);
        }
        if (mobile !== undefined && mobile !== null) {
            queryParameters = queryParameters.set('mobile', <any>mobile);
        }
        if (phone !== undefined && phone !== null) {
            queryParameters = queryParameters.set('phone', <any>phone);
        }
        if (town !== undefined && town !== null) {
            queryParameters = queryParameters.set('town', <any>town);
        }
        if (addressLine1 !== undefined && addressLine1 !== null) {
            queryParameters = queryParameters.set('addressLine1', <any>addressLine1);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CustomerResultView>>('get',`${this.basePath}/api/Customer/GetAllByAdditionalSeparated`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a List of Customers with Additional data, depending on the Filter or Sorting
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param memberCode 
     * @param barcode 
     * @param lastName 
     * @param firstName 
     * @param email 
     * @param idCard 
     * @param mobile 
     * @param phone 
     * @param town 
     * @param addressLine1 
     * @param scheme 
     * @param xApiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerGetAllKwiksaveCustomersByAdditionalSeparatedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, memberCode?: string, barcode?: string, lastName?: string, firstName?: string, email?: string, idCard?: string, mobile?: string, phone?: string, town?: string, addressLine1?: string, scheme?: string, xApiKey?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerResultView>>;
    public apiCustomerGetAllKwiksaveCustomersByAdditionalSeparatedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, memberCode?: string, barcode?: string, lastName?: string, firstName?: string, email?: string, idCard?: string, mobile?: string, phone?: string, town?: string, addressLine1?: string, scheme?: string, xApiKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerResultView>>>;
    public apiCustomerGetAllKwiksaveCustomersByAdditionalSeparatedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, memberCode?: string, barcode?: string, lastName?: string, firstName?: string, email?: string, idCard?: string, mobile?: string, phone?: string, town?: string, addressLine1?: string, scheme?: string, xApiKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerResultView>>>;
    public apiCustomerGetAllKwiksaveCustomersByAdditionalSeparatedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, memberCode?: string, barcode?: string, lastName?: string, firstName?: string, email?: string, idCard?: string, mobile?: string, phone?: string, town?: string, addressLine1?: string, scheme?: string, xApiKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (memberCode !== undefined && memberCode !== null) {
            queryParameters = queryParameters.set('memberCode', <any>memberCode);
        }
        if (barcode !== undefined && barcode !== null) {
            queryParameters = queryParameters.set('barcode', <any>barcode);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any>firstName);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (idCard !== undefined && idCard !== null) {
            queryParameters = queryParameters.set('idCard', <any>idCard);
        }
        if (mobile !== undefined && mobile !== null) {
            queryParameters = queryParameters.set('mobile', <any>mobile);
        }
        if (phone !== undefined && phone !== null) {
            queryParameters = queryParameters.set('phone', <any>phone);
        }
        if (town !== undefined && town !== null) {
            queryParameters = queryParameters.set('town', <any>town);
        }
        if (addressLine1 !== undefined && addressLine1 !== null) {
            queryParameters = queryParameters.set('addressLine1', <any>addressLine1);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CustomerResultView>>('get',`${this.basePath}/api/Customer/GetAllKwiksaveCustomersByAdditionalSeparated`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Monetary Value
     * 
     * @param code 
     * @param idCard 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerGetMonetaryValueGet(code?: string, idCard?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<MemberBalanceResultView>;
    public apiCustomerGetMonetaryValueGet(code?: string, idCard?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MemberBalanceResultView>>;
    public apiCustomerGetMonetaryValueGet(code?: string, idCard?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MemberBalanceResultView>>;
    public apiCustomerGetMonetaryValueGet(code?: string, idCard?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (idCard !== undefined && idCard !== null) {
            queryParameters = queryParameters.set('idCard', <any>idCard);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MemberBalanceResultView>('get',`${this.basePath}/api/Customer/GetMonetaryValue`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve the Schemes where the Customer is subscribed
     * 
     * @param id 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerGetSchemesGet(id?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BasicSchemeResultViewWithCustomerPolicyCheck>>;
    public apiCustomerGetSchemesGet(id?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BasicSchemeResultViewWithCustomerPolicyCheck>>>;
    public apiCustomerGetSchemesGet(id?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BasicSchemeResultViewWithCustomerPolicyCheck>>>;
    public apiCustomerGetSchemesGet(id?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BasicSchemeResultViewWithCustomerPolicyCheck>>('get',`${this.basePath}/api/Customer/GetSchemes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the Statement for a given Customer and Scheme
     * 
     * @param scheme 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerGetStatementGet(scheme: string, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerStatementResultView>;
    public apiCustomerGetStatementGet(scheme: string, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerStatementResultView>>;
    public apiCustomerGetStatementGet(scheme: string, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerStatementResultView>>;
    public apiCustomerGetStatementGet(scheme: string, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiCustomerGetStatementGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerStatementResultView>('get',`${this.basePath}/api/Customer/GetStatement`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the number of visits for a Customer within a Scheme
     * 
     * @param scheme 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerGetVisitsGet(scheme: string, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<Item>;
    public apiCustomerGetVisitsGet(scheme: string, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Item>>;
    public apiCustomerGetVisitsGet(scheme: string, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Item>>;
    public apiCustomerGetVisitsGet(scheme: string, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiCustomerGetVisitsGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Item>('get',`${this.basePath}/api/Customer/GetVisits`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerIProLogsGet(scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerIProLogsGet(scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerIProLogsGet(scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerIProLogsGet(scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/IProLogs`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set the Deleted flag of a Customer to true
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerIdDelete(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerIdDelete(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerIdDelete(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerIdDelete(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiCustomerIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Customer/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Customer by id.
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerIdGet(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiCustomerIdGet(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiCustomerIdGet(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiCustomerIdGet(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiCustomerIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerResultView>('get',`${this.basePath}/api/Customer/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheme 
     * @param jobId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerImportProgressGet(scheme?: string, jobId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerImportProgressGet(scheme?: string, jobId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerImportProgressGet(scheme?: string, jobId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerImportProgressGet(scheme?: string, jobId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (jobId !== undefined && jobId !== null) {
            queryParameters = queryParameters.set('jobId', <any>jobId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/ImportProgress`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if a user with the specified Additional Field and value already exists
     * 
     * @param additionalFieldKey 
     * @param additionalFieldValue 
     * @param scheme 
     * @param excludedCustomerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerIsAdditionalFieldUniqueGet(additionalFieldKey?: string, additionalFieldValue?: string, scheme?: string, excludedCustomerId?: string, observe?: 'body', reportProgress?: boolean): Observable<IsAdditionalFieldUniqueResultView>;
    public apiCustomerIsAdditionalFieldUniqueGet(additionalFieldKey?: string, additionalFieldValue?: string, scheme?: string, excludedCustomerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IsAdditionalFieldUniqueResultView>>;
    public apiCustomerIsAdditionalFieldUniqueGet(additionalFieldKey?: string, additionalFieldValue?: string, scheme?: string, excludedCustomerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IsAdditionalFieldUniqueResultView>>;
    public apiCustomerIsAdditionalFieldUniqueGet(additionalFieldKey?: string, additionalFieldValue?: string, scheme?: string, excludedCustomerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (additionalFieldKey !== undefined && additionalFieldKey !== null) {
            queryParameters = queryParameters.set('additionalFieldKey', <any>additionalFieldKey);
        }
        if (additionalFieldValue !== undefined && additionalFieldValue !== null) {
            queryParameters = queryParameters.set('additionalFieldValue', <any>additionalFieldValue);
        }
        if (excludedCustomerId !== undefined && excludedCustomerId !== null) {
            queryParameters = queryParameters.set('excludedCustomerId', <any>excludedCustomerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IsAdditionalFieldUniqueResultView>('get',`${this.basePath}/api/Customer/IsAdditionalFieldUnique`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param barcode 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerIsBarcodeUniqueBarcodeGet(barcode: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerIsBarcodeUniqueBarcodeGet(barcode: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerIsBarcodeUniqueBarcodeGet(barcode: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerIsBarcodeUniqueBarcodeGet(barcode: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (barcode === null || barcode === undefined) {
            throw new Error('Required parameter barcode was null or undefined when calling apiCustomerIsBarcodeUniqueBarcodeGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/IsBarcodeUnique/${encodeURIComponent(String(barcode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for Saas customers to join scheme by id
     * 
     * @param schemeId 
     * @param body 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerJoinSchemeSchemeIdPost(schemeId: string, body?: JoinSchemeInsertView, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerJoinSchemeSchemeIdPost(schemeId: string, body?: JoinSchemeInsertView, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerJoinSchemeSchemeIdPost(schemeId: string, body?: JoinSchemeInsertView, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerJoinSchemeSchemeIdPost(schemeId: string, body?: JoinSchemeInsertView, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiCustomerJoinSchemeSchemeIdPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/JoinScheme/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Assign Email and Password to Kwiksave User
     * 
     * @param body 
     * @param xApiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerKwiksaveLinkCustomerPost(body?: AssignExistingRequestView, xApiKey?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiCustomerKwiksaveLinkCustomerPost(body?: AssignExistingRequestView, xApiKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiCustomerKwiksaveLinkCustomerPost(body?: AssignExistingRequestView, xApiKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiCustomerKwiksaveLinkCustomerPost(body?: AssignExistingRequestView, xApiKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerResultView>('post',`${this.basePath}/api/Customer/KwiksaveLinkCustomer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for Saas customers to leave scheme by id
     * 
     * @param schemeId 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerLeaveSchemeSchemeIdPost(schemeId: string, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerLeaveSchemeSchemeIdPost(schemeId: string, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerLeaveSchemeSchemeIdPost(schemeId: string, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerLeaveSchemeSchemeIdPost(schemeId: string, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiCustomerLeaveSchemeSchemeIdPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/LeaveScheme/${encodeURIComponent(String(schemeId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to get level history for customer
     * 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerLevelHistoryGet(customerId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerLevelHistoryGet(customerId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerLevelHistoryGet(customerId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerLevelHistoryGet(customerId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/LevelHistory`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerLevelProgressByProfileIdProfileIdPost(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerLevelProgressByProfileIdProfileIdPost(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerLevelProgressByProfileIdProfileIdPost(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerLevelProgressByProfileIdProfileIdPost(profileId: string, xApiKey?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiCustomerLevelProgressByProfileIdProfileIdPost.');
        }



        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/LevelProgress/byProfileId/${encodeURIComponent(String(profileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param customerId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerLevelProgressCustomerIdPost(customerId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerLevelProgressCustomerIdPost(customerId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerLevelProgressCustomerIdPost(customerId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerLevelProgressCustomerIdPost(customerId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiCustomerLevelProgressCustomerIdPost.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/LevelProgress/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of the Customers Levels by customerId
     * 
     * @param customerId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerLevelsCustomerIdGet(customerId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProgressGroupedByTier>>;
    public apiCustomerLevelsCustomerIdGet(customerId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProgressGroupedByTier>>>;
    public apiCustomerLevelsCustomerIdGet(customerId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProgressGroupedByTier>>>;
    public apiCustomerLevelsCustomerIdGet(customerId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiCustomerLevelsCustomerIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ProgressGroupedByTier>>('get',`${this.basePath}/api/Customer/Levels/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Links a Card Token to a Customer
     * This endpoint creates a link between a card token and a customer.
     * @param xApiKey 
     * @param xScheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerLinkCardTokenPost(xApiKey: string, xScheme: string, body?: CardTokenInsertView, observe?: 'body', reportProgress?: boolean): Observable<CardToken>;
    public apiCustomerLinkCardTokenPost(xApiKey: string, xScheme: string, body?: CardTokenInsertView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CardToken>>;
    public apiCustomerLinkCardTokenPost(xApiKey: string, xScheme: string, body?: CardTokenInsertView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CardToken>>;
    public apiCustomerLinkCardTokenPost(xApiKey: string, xScheme: string, body?: CardTokenInsertView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiCustomerLinkCardTokenPost.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiCustomerLinkCardTokenPost.');
        }


        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CardToken>('post',`${this.basePath}/api/Customer/LinkCardToken`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset barcode for a Customer
     * 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerNewBarcodePost(customerId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerNewBarcodePost(customerId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerNewBarcodePost(customerId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerNewBarcodePost(customerId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/NewBarcode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset barcode for a Customer
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerNewCustomBarcodePost(body?: BarcodeChangeView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerNewCustomBarcodePost(body?: BarcodeChangeView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerNewCustomBarcodePost(body?: BarcodeChangeView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerNewCustomBarcodePost(body?: BarcodeChangeView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/NewCustomBarcode`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register a new Customer
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerPost(body?: CustomerInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiCustomerPost(body?: CustomerInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiCustomerPost(body?: CustomerInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiCustomerPost(body?: CustomerInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerResultView>('post',`${this.basePath}/api/Customer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register a new Customer with additional details
     * 
     * @param scheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerPostAdditionalPost(scheme: string, body?: CustomerWithAdditionalFieldsInsertView, observe?: 'body', reportProgress?: boolean): Observable<CustomerWithAdditionalFieldsResultView>;
    public apiCustomerPostAdditionalPost(scheme: string, body?: CustomerWithAdditionalFieldsInsertView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerWithAdditionalFieldsResultView>>;
    public apiCustomerPostAdditionalPost(scheme: string, body?: CustomerWithAdditionalFieldsInsertView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerWithAdditionalFieldsResultView>>;
    public apiCustomerPostAdditionalPost(scheme: string, body?: CustomerWithAdditionalFieldsInsertView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiCustomerPostAdditionalPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerWithAdditionalFieldsResultView>('post',`${this.basePath}/api/Customer/PostAdditional`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify a Customer
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerPut(body?: CustomerPutView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerPut(body?: CustomerPutView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerPut(body?: CustomerPutView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerPut(body?: CustomerPutView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Customer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify a new Customer with additional details
     * 
     * @param scheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerPutAdditionalPut(scheme: string, body?: CustomerWithAdditionalFieldsPutView, observe?: 'body', reportProgress?: boolean): Observable<CustomerWithAdditionalFieldsResultView>;
    public apiCustomerPutAdditionalPut(scheme: string, body?: CustomerWithAdditionalFieldsPutView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerWithAdditionalFieldsResultView>>;
    public apiCustomerPutAdditionalPut(scheme: string, body?: CustomerWithAdditionalFieldsPutView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerWithAdditionalFieldsResultView>>;
    public apiCustomerPutAdditionalPut(scheme: string, body?: CustomerWithAdditionalFieldsPutView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiCustomerPutAdditionalPut.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerWithAdditionalFieldsResultView>('put',`${this.basePath}/api/Customer/PutAdditional`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends email verification to given profile
     * 
     * @param profileId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerSendVerifyEmailByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerSendVerifyEmailByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerSendVerifyEmailByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerSendVerifyEmailByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiCustomerSendVerifyEmailByProfileIdProfileIdGet.');
        }



        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/SendVerifyEmail/byProfileId/${encodeURIComponent(String(profileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to send customer email verification
     * 
     * @param customerID 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerSendVerifyEmailGet(customerID?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerSendVerifyEmailGet(customerID?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerSendVerifyEmailGet(customerID?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerSendVerifyEmailGet(customerID?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerID !== undefined && customerID !== null) {
            queryParameters = queryParameters.set('CustomerID', <any>customerID);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/SendVerifyEmail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to send customer mobile verification
     * 
     * @param customerID 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerSendVerifyMobileGet(customerID?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerSendVerifyMobileGet(customerID?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerSendVerifyMobileGet(customerID?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerSendVerifyMobileGet(customerID?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerID !== undefined && customerID !== null) {
            queryParameters = queryParameters.set('CustomerID', <any>customerID);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/SendVerifyMobile`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets Status of SMS from Twilio
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerSmsStatusPost(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerSmsStatusPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerSmsStatusPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerSmsStatusPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/SmsStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param customerId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerStatisticsCustomerIdGet(customerId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerStatisticsCustomerIdGet(customerId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerStatisticsCustomerIdGet(customerId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerStatisticsCustomerIdGet(customerId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiCustomerStatisticsCustomerIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/Statistics/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to get status history for customer
     * 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerStatusHistoryGet(customerId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerStatusHistoryGet(customerId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerStatusHistoryGet(customerId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerStatusHistoryGet(customerId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/StatusHistory`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Place a customer account on hold.
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerSuspendIdPut(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerSuspendIdPut(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerSuspendIdPut(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerSuspendIdPut(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiCustomerSuspendIdPut.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/Customer/Suspend/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for customers to delete their account
     * 
     * @param customerId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerTerminateAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerTerminateAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerTerminateAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerTerminateAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiCustomerTerminateAccountCustomerIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/TerminateAccount/${encodeURIComponent(String(customerId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * UpdateOnPos
     * 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerUpdateOnPosGet(customerId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerUpdateOnPosGet(customerId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerUpdateOnPosGet(customerId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerUpdateOnPosGet(customerId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/UpdateOnPos`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for customer email verification
     * 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerVerifyEmailCheckGet(customerId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerVerifyEmailCheckGet(customerId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerVerifyEmailCheckGet(customerId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerVerifyEmailCheckGet(customerId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/VerifyEmailCheck`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerVerifyEmailCodePost(code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerVerifyEmailCodePost(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerVerifyEmailCodePost(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerVerifyEmailCodePost(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling apiCustomerVerifyEmailCodePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/verify-email/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for customer email verification
     * 
     * @param verificationCode 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerVerifyEmailGet(verificationCode?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerVerifyEmailGet(verificationCode?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerVerifyEmailGet(verificationCode?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerVerifyEmailGet(verificationCode?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (verificationCode !== undefined && verificationCode !== null) {
            queryParameters = queryParameters.set('verificationCode', <any>verificationCode);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/VerifyEmail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for customer mobile verification
     * 
     * @param verificationCode 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerVerifyMobileGet(verificationCode?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerVerifyMobileGet(verificationCode?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerVerifyMobileGet(verificationCode?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerVerifyMobileGet(verificationCode?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (verificationCode !== undefined && verificationCode !== null) {
            queryParameters = queryParameters.set('verificationCode', <any>verificationCode);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/VerifyMobile`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerVerifyTokenPost(body?: VerifyCustomerTokenRequestView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerVerifyTokenPost(body?: VerifyCustomerTokenRequestView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerVerifyTokenPost(body?: VerifyCustomerTokenRequestView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerVerifyTokenPost(body?: VerifyCustomerTokenRequestView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/VerifyToken`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for wallet customers to close their account
     * 
     * @param customerId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerWalletCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerWalletCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerWalletCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerWalletCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiCustomerWalletCloseAccountCustomerIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Customer/Wallet/CloseAccount/${encodeURIComponent(String(customerId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set the Deleted flag of a Customer to true
     * 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerWalletCustomerIdDelete(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerWalletCustomerIdDelete(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerWalletCustomerIdDelete(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerWalletCustomerIdDelete(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiCustomerWalletCustomerIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Customer/Wallet/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Wallet Customer by Id
     * 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerWalletCustomerIdGet(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<WalletCustomerResultView>;
    public apiCustomerWalletCustomerIdGet(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WalletCustomerResultView>>;
    public apiCustomerWalletCustomerIdGet(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WalletCustomerResultView>>;
    public apiCustomerWalletCustomerIdGet(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiCustomerWalletCustomerIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WalletCustomerResultView>('get',`${this.basePath}/api/Customer/Wallet/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify a Wallet Customer
     * 
     * @param customerId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerWalletCustomerIdPut(customerId: string, body?: WalletCustomerPutView, observe?: 'body', reportProgress?: boolean): Observable<WalletCustomerResultView>;
    public apiCustomerWalletCustomerIdPut(customerId: string, body?: WalletCustomerPutView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WalletCustomerResultView>>;
    public apiCustomerWalletCustomerIdPut(customerId: string, body?: WalletCustomerPutView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WalletCustomerResultView>>;
    public apiCustomerWalletCustomerIdPut(customerId: string, body?: WalletCustomerPutView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiCustomerWalletCustomerIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<WalletCustomerResultView>('put',`${this.basePath}/api/Customer/Wallet/${encodeURIComponent(String(customerId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve the Schemes where the Customer is suscribed
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerWalletGetSchemesGet(filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BasicSchemeResultViewWithCustomerPolicyCheck>>;
    public apiCustomerWalletGetSchemesGet(filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BasicSchemeResultViewWithCustomerPolicyCheck>>>;
    public apiCustomerWalletGetSchemesGet(filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BasicSchemeResultViewWithCustomerPolicyCheck>>>;
    public apiCustomerWalletGetSchemesGet(filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BasicSchemeResultViewWithCustomerPolicyCheck>>('get',`${this.basePath}/api/Customer/Wallet/GetSchemes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register a new Wallet Customer
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerWalletPost(body?: WalletCustomerInsertView, observe?: 'body', reportProgress?: boolean): Observable<WalletCustomerResultView>;
    public apiCustomerWalletPost(body?: WalletCustomerInsertView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WalletCustomerResultView>>;
    public apiCustomerWalletPost(body?: WalletCustomerInsertView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WalletCustomerResultView>>;
    public apiCustomerWalletPost(body?: WalletCustomerInsertView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<WalletCustomerResultView>('post',`${this.basePath}/api/Customer/Wallet`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload a Profile Picture
     * Send a file in the body of the request, this file will be linked as the profile picture of the customer whose Id was passed in the query parameters.
     * @param file 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerWalletProfilePicturePostForm(file?: Blob, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<WalletCustomerResultView>;
    public apiCustomerWalletProfilePicturePostForm(file?: Blob, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WalletCustomerResultView>>;
    public apiCustomerWalletProfilePicturePostForm(file?: Blob, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WalletCustomerResultView>>;
    public apiCustomerWalletProfilePicturePostForm(file?: Blob, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<WalletCustomerResultView>('post',`${this.basePath}/api/Customer/Wallet/ProfilePicture`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes a Profile Picture
     * Removes the customer&#x27;s Profile Picture.
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerWalletRemoveProfilePicturePut(customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<WalletCustomerResultView>;
    public apiCustomerWalletRemoveProfilePicturePut(customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WalletCustomerResultView>>;
    public apiCustomerWalletRemoveProfilePicturePut(customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WalletCustomerResultView>>;
    public apiCustomerWalletRemoveProfilePicturePut(customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WalletCustomerResultView>('put',`${this.basePath}/api/Customer/Wallet/RemoveProfilePicture`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to send customer email verification
     * 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerWalletSendVerifyEmailGet(customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerWalletSendVerifyEmailGet(customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerWalletSendVerifyEmailGet(customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerWalletSendVerifyEmailGet(customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('CustomerId', <any>customerId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/Wallet/SendVerifyEmail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint for wallet customer email verification
     * 
     * @param verificationCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCustomerWalletVerifyEmailGet(verificationCode?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCustomerWalletVerifyEmailGet(verificationCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCustomerWalletVerifyEmailGet(verificationCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCustomerWalletVerifyEmailGet(verificationCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (verificationCode !== undefined && verificationCode !== null) {
            queryParameters = queryParameters.set('verificationCode', <any>verificationCode);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Customer/Wallet/VerifyEmail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Balance by Customer Barcode
     * This endpoint returns the balance of a customer by the barcode.
     * @param customerBarCode 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerBalanceByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<PointBalanceSummaryResult>;
    public apiPosCustomerBalanceByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PointBalanceSummaryResult>>;
    public apiPosCustomerBalanceByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PointBalanceSummaryResult>>;
    public apiPosCustomerBalanceByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerBarCode === null || customerBarCode === undefined) {
            throw new Error('Required parameter customerBarCode was null or undefined when calling apiPosCustomerBalanceByBarcodeCustomerBarCodeGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerBalanceByBarcodeCustomerBarCodeGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerBalanceByBarcodeCustomerBarCodeGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PointBalanceSummaryResult>('get',`${this.basePath}/api/Pos/Customer/Balance/ByBarcode/${encodeURIComponent(String(customerBarCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Balance by Customer ID
     * This endpoint returns the balance of a customer by the id.
     * @param customerId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerBalanceCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<PointBalanceSummaryResult>;
    public apiPosCustomerBalanceCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PointBalanceSummaryResult>>;
    public apiPosCustomerBalanceCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PointBalanceSummaryResult>>;
    public apiPosCustomerBalanceCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiPosCustomerBalanceCustomerIdGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerBalanceCustomerIdGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerBalanceCustomerIdGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PointBalanceSummaryResult>('get',`${this.basePath}/api/Pos/Customer/Balance/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customer Balance
     * This endpoint returns the balance of a customer by the identifier.
     * @param xApiKey 
     * @param xScheme 
     * @param id 
     * @param barCode 
     * @param areaCode 
     * @param mobileNumber 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerBalanceGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'body', reportProgress?: boolean): Observable<PointBalanceSummaryResult>;
    public apiPosCustomerBalanceGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PointBalanceSummaryResult>>;
    public apiPosCustomerBalanceGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PointBalanceSummaryResult>>;
    public apiPosCustomerBalanceGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerBalanceGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerBalanceGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (barCode !== undefined && barCode !== null) {
            queryParameters = queryParameters.set('barCode', <any>barCode);
        }
        if (areaCode !== undefined && areaCode !== null) {
            queryParameters = queryParameters.set('areaCode', <any>areaCode);
        }
        if (mobileNumber !== undefined && mobileNumber !== null) {
            queryParameters = queryParameters.set('mobileNumber', <any>mobileNumber);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PointBalanceSummaryResult>('get',`${this.basePath}/api/Pos/Customer/Balance`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customer by Barcode
     * This endpoint returns one customer by the barcode.
     * @param customerBarCode 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultViewPos>;
    public apiPosCustomerByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultViewPos>>;
    public apiPosCustomerByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultViewPos>>;
    public apiPosCustomerByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerBarCode === null || customerBarCode === undefined) {
            throw new Error('Required parameter customerBarCode was null or undefined when calling apiPosCustomerByBarcodeCustomerBarCodeGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerByBarcodeCustomerBarCodeGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerByBarcodeCustomerBarCodeGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerResultViewPos>('get',`${this.basePath}/api/Pos/Customer/ByBarcode/${encodeURIComponent(String(customerBarCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customer
     * This endpoint returns one customer by the identifier passed. This can be either the id, barCode, email or mobile number of the customer.
     * @param xApiKey 
     * @param xScheme 
     * @param id 
     * @param barCode 
     * @param areaCode 
     * @param mobileNumber 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerByIdentifierGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiPosCustomerByIdentifierGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiPosCustomerByIdentifierGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiPosCustomerByIdentifierGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerByIdentifierGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerByIdentifierGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (barCode !== undefined && barCode !== null) {
            queryParameters = queryParameters.set('barCode', <any>barCode);
        }
        if (areaCode !== undefined && areaCode !== null) {
            queryParameters = queryParameters.set('areaCode', <any>areaCode);
        }
        if (mobileNumber !== undefined && mobileNumber !== null) {
            queryParameters = queryParameters.set('mobileNumber', <any>mobileNumber);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerResultView>('get',`${this.basePath}/api/Pos/Customer/ByIdentifier`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customer by ID
     * This endpoint returns one customer by the id.
     * @param customerId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiPosCustomerCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiPosCustomerCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiPosCustomerCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiPosCustomerCustomerIdGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerCustomerIdGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerCustomerIdGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerResultView>('get',`${this.basePath}/api/Pos/Customer/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customers
     * This endpoint returns a list of customers. These can be filtered, sorted and paged by the query parameters passed.
     * @param xApiKey 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param pointBalanceOperator 
     * @param pointBalanceValue 
     * @param tagOperator 
     * @param tagIds 
     * @param levelOperator 
     * @param levelIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerResultView>>;
    public apiPosCustomerGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerResultView>>>;
    public apiPosCustomerGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerResultView>>>;
    public apiPosCustomerGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerGet.');
        }











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (pointBalanceOperator !== undefined && pointBalanceOperator !== null) {
            queryParameters = queryParameters.set('PointBalanceOperator', <any>pointBalanceOperator);
        }
        if (pointBalanceValue !== undefined && pointBalanceValue !== null) {
            queryParameters = queryParameters.set('PointBalanceValue', <any>pointBalanceValue);
        }
        if (tagOperator !== undefined && tagOperator !== null) {
            queryParameters = queryParameters.set('TagOperator', <any>tagOperator);
        }
        if (tagIds) {
            tagIds.forEach((element) => {
                queryParameters = queryParameters.append('TagIds', <any>element);
            })
        }
        if (levelOperator !== undefined && levelOperator !== null) {
            queryParameters = queryParameters.set('LevelOperator', <any>levelOperator);
        }
        if (levelIds) {
            levelIds.forEach((element) => {
                queryParameters = queryParameters.append('LevelIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CustomerResultView>>('get',`${this.basePath}/api/Pos/Customer`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Join a Guest to the Scheme
     * Joins the guest account to the scheme
     * @param xApiKey 
     * @param customerId 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerGuestJoinCustomerIdPut(xApiKey: string, customerId: string, body?: UpdateGuestAccountView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiPosCustomerGuestJoinCustomerIdPut(xApiKey: string, customerId: string, body?: UpdateGuestAccountView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiPosCustomerGuestJoinCustomerIdPut(xApiKey: string, customerId: string, body?: UpdateGuestAccountView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiPosCustomerGuestJoinCustomerIdPut(xApiKey: string, customerId: string, body?: UpdateGuestAccountView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerGuestJoinCustomerIdPut.');
        }

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiPosCustomerGuestJoinCustomerIdPut.');
        }



        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerResultView>('put',`${this.basePath}/api/Pos/Customer/GuestJoin/${encodeURIComponent(String(customerId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Guest Customer
     * This endpoint allows you to create a guest customer account.
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerGuestPost(xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultViewPos>;
    public apiPosCustomerGuestPost(xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultViewPos>>;
    public apiPosCustomerGuestPost(xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultViewPos>>;
    public apiPosCustomerGuestPost(xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerGuestPost.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerGuestPost.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerResultViewPos>('post',`${this.basePath}/api/Pos/Customer/Guest`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Levels by Customer Barcode
     * This endpoint returns the levels of a customer by the barcode.
     * @param customerBarCode 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerLevelsByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProgressGroupedByTier>>;
    public apiPosCustomerLevelsByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProgressGroupedByTier>>>;
    public apiPosCustomerLevelsByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProgressGroupedByTier>>>;
    public apiPosCustomerLevelsByBarcodeCustomerBarCodeGet(customerBarCode: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerBarCode === null || customerBarCode === undefined) {
            throw new Error('Required parameter customerBarCode was null or undefined when calling apiPosCustomerLevelsByBarcodeCustomerBarCodeGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerLevelsByBarcodeCustomerBarCodeGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerLevelsByBarcodeCustomerBarCodeGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ProgressGroupedByTier>>('get',`${this.basePath}/api/Pos/Customer/Levels/ByBarcode/${encodeURIComponent(String(customerBarCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Levels by Customer ID
     * This endpoint returns the levels of a customer by the id.
     * @param customerId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerLevelsCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProgressGroupedByTier>>;
    public apiPosCustomerLevelsCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProgressGroupedByTier>>>;
    public apiPosCustomerLevelsCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProgressGroupedByTier>>>;
    public apiPosCustomerLevelsCustomerIdGet(customerId: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiPosCustomerLevelsCustomerIdGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerLevelsCustomerIdGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerLevelsCustomerIdGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ProgressGroupedByTier>>('get',`${this.basePath}/api/Pos/Customer/Levels/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customer Levels
     * This endpoint returns the levels of a customer by the identifier.
     * @param xApiKey 
     * @param xScheme 
     * @param id 
     * @param barCode 
     * @param areaCode 
     * @param mobileNumber 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerLevelsGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProgressGroupedByTier>>;
    public apiPosCustomerLevelsGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProgressGroupedByTier>>>;
    public apiPosCustomerLevelsGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProgressGroupedByTier>>>;
    public apiPosCustomerLevelsGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerLevelsGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerLevelsGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (barCode !== undefined && barCode !== null) {
            queryParameters = queryParameters.set('barCode', <any>barCode);
        }
        if (areaCode !== undefined && areaCode !== null) {
            queryParameters = queryParameters.set('areaCode', <any>areaCode);
        }
        if (mobileNumber !== undefined && mobileNumber !== null) {
            queryParameters = queryParameters.set('mobileNumber', <any>mobileNumber);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ProgressGroupedByTier>>('get',`${this.basePath}/api/Pos/Customer/Levels`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get List of Customers
     * This endpoint returns a list of customers. These can be filtered, sorted and paged by the query parameters passed.
     * @param xApiKey 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param pointBalanceOperator 
     * @param pointBalanceValue 
     * @param tagOperator 
     * @param tagIds 
     * @param levelOperator 
     * @param levelIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerListGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerResultView>>;
    public apiPosCustomerListGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerResultView>>>;
    public apiPosCustomerListGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerResultView>>>;
    public apiPosCustomerListGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, pointBalanceOperator?: string, pointBalanceValue?: number, tagOperator?: ListCompareType, tagIds?: Array<string>, levelOperator?: ListCompareType, levelIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerListGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerListGet.');
        }











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (pointBalanceOperator !== undefined && pointBalanceOperator !== null) {
            queryParameters = queryParameters.set('PointBalanceOperator', <any>pointBalanceOperator);
        }
        if (pointBalanceValue !== undefined && pointBalanceValue !== null) {
            queryParameters = queryParameters.set('PointBalanceValue', <any>pointBalanceValue);
        }
        if (tagOperator !== undefined && tagOperator !== null) {
            queryParameters = queryParameters.set('TagOperator', <any>tagOperator);
        }
        if (tagIds) {
            tagIds.forEach((element) => {
                queryParameters = queryParameters.append('TagIds', <any>element);
            })
        }
        if (levelOperator !== undefined && levelOperator !== null) {
            queryParameters = queryParameters.set('LevelOperator', <any>levelOperator);
        }
        if (levelIds) {
            levelIds.forEach((element) => {
                queryParameters = queryParameters.append('LevelIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CustomerResultView>>('get',`${this.basePath}/api/Pos/Customer/List`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customer Offers
     * 
     * @param xApiKey 
     * @param xScheme 
     * @param id 
     * @param barCode 
     * @param areaCode 
     * @param mobileNumber 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerOffersGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerOffersResultView>;
    public apiPosCustomerOffersGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerOffersResultView>>;
    public apiPosCustomerOffersGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerOffersResultView>>;
    public apiPosCustomerOffersGet(xApiKey: string, xScheme: string, id?: string, barCode?: string, areaCode?: string, mobileNumber?: string, email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerOffersGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerOffersGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (barCode !== undefined && barCode !== null) {
            queryParameters = queryParameters.set('barCode', <any>barCode);
        }
        if (areaCode !== undefined && areaCode !== null) {
            queryParameters = queryParameters.set('areaCode', <any>areaCode);
        }
        if (mobileNumber !== undefined && mobileNumber !== null) {
            queryParameters = queryParameters.set('mobileNumber', <any>mobileNumber);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerOffersResultView>('get',`${this.basePath}/api/Pos/Customer/Offers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post Customer
     * This endpoint allows you to create a customer account.
     * @param xApiKey 
     * @param xScheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCustomerPost(xApiKey: string, xScheme: string, body?: BasicCustomerInsertView, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultViewPos>;
    public apiPosCustomerPost(xApiKey: string, xScheme: string, body?: BasicCustomerInsertView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultViewPos>>;
    public apiPosCustomerPost(xApiKey: string, xScheme: string, body?: BasicCustomerInsertView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultViewPos>>;
    public apiPosCustomerPost(xApiKey: string, xScheme: string, body?: BasicCustomerInsertView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCustomerPost.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCustomerPost.');
        }


        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerResultViewPos>('post',`${this.basePath}/api/Pos/Customer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Balance
     * This endpoint returns the customer’s points and monetary balance.
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerBalanceGet(xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<PointBalanceSummaryResult>;
    public apiV2CustomerBalanceGet(xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PointBalanceSummaryResult>>;
    public apiV2CustomerBalanceGet(xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PointBalanceSummaryResult>>;
    public apiV2CustomerBalanceGet(xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PointBalanceSummaryResult>('get',`${this.basePath}/api/v2/Customer/Balance`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Closes the Customer account
     * Endpoint for Customers to close their accounts from Loyale. The customerId is passed in the route and their password is inputted in the body of the request in the Password field. If the password is correct this endpoint suspends the account and leaves all its joined levels.
     * @param customerId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiV2CustomerCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiV2CustomerCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiV2CustomerCloseAccountCustomerIdPost(customerId: string, body?: PasswordView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiV2CustomerCloseAccountCustomerIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v2/Customer/CloseAccount/${encodeURIComponent(String(customerId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Completes a customer account
     * Completes the account of the matching customer
     * @param body 
     * @param xScheme 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerCompleteCustomerAccountPut(body?: AccountCompletionView, xScheme?: string, code?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiV2CustomerCompleteCustomerAccountPut(body?: AccountCompletionView, xScheme?: string, code?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiV2CustomerCompleteCustomerAccountPut(body?: AccountCompletionView, xScheme?: string, code?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiV2CustomerCompleteCustomerAccountPut(body?: AccountCompletionView, xScheme?: string, code?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerResultView>('put',`${this.basePath}/api/v2/Customer/CompleteCustomerAccount`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Customer by ID
     * This endpoint returns one customer by the id.
     * @param customerId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerCustomerIdGet(customerId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiV2CustomerCustomerIdGet(customerId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiV2CustomerCustomerIdGet(customerId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiV2CustomerCustomerIdGet(customerId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiV2CustomerCustomerIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerResultView>('get',`${this.basePath}/api/v2/Customer/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Join Scheme
     * This endpoint allows the customer to join a scheme.
     * @param schemeId 
     * @param body 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerJoinSchemeSchemeIdPost(schemeId: string, body?: JoinSchemeInsertView, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiV2CustomerJoinSchemeSchemeIdPost(schemeId: string, body?: JoinSchemeInsertView, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiV2CustomerJoinSchemeSchemeIdPost(schemeId: string, body?: JoinSchemeInsertView, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiV2CustomerJoinSchemeSchemeIdPost(schemeId: string, body?: JoinSchemeInsertView, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiV2CustomerJoinSchemeSchemeIdPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v2/Customer/JoinScheme/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Leave Scheme
     * This endpoint allows the customer to leave a scheme.
     * @param schemeId 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerLeaveSchemeSchemeIdPost(schemeId: string, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiV2CustomerLeaveSchemeSchemeIdPost(schemeId: string, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiV2CustomerLeaveSchemeSchemeIdPost(schemeId: string, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiV2CustomerLeaveSchemeSchemeIdPost(schemeId: string, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiV2CustomerLeaveSchemeSchemeIdPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/v2/Customer/LeaveScheme/${encodeURIComponent(String(schemeId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Levels
     * This endpoint returns the customer&#x27;s levels.
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerLevelsGet(xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProgressGroupedByTier>>;
    public apiV2CustomerLevelsGet(xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProgressGroupedByTier>>>;
    public apiV2CustomerLevelsGet(xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProgressGroupedByTier>>>;
    public apiV2CustomerLevelsGet(xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiV2CustomerLevelsGet.');
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ProgressGroupedByTier>>('get',`${this.basePath}/api/v2/Customer/Levels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post Customer
     * This endpoint allows the customer to create their account.
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerPost(body?: CustomerInsertViewV2, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiV2CustomerPost(body?: CustomerInsertViewV2, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiV2CustomerPost(body?: CustomerInsertViewV2, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiV2CustomerPost(body?: CustomerInsertViewV2, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerResultView>('post',`${this.basePath}/api/v2/Customer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post Profile Picture
     * This endpoint allows the customer to save their profile picture.
     * @param xScheme 
     * @param file 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerProfilePicturePostForm(xScheme: string, file?: Blob, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerWithAdditionalFieldsResultView>;
    public apiV2CustomerProfilePicturePostForm(xScheme: string, file?: Blob, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerWithAdditionalFieldsResultView>>;
    public apiV2CustomerProfilePicturePostForm(xScheme: string, file?: Blob, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerWithAdditionalFieldsResultView>>;
    public apiV2CustomerProfilePicturePostForm(xScheme: string, file?: Blob, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiV2CustomerProfilePicturePost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<CustomerWithAdditionalFieldsResultView>('post',`${this.basePath}/api/v2/Customer/ProfilePicture`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Customer
     * This endpoint allows the customer to update their account.
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerPut(body?: CustomerPutViewV2, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerResultView>;
    public apiV2CustomerPut(body?: CustomerPutViewV2, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerResultView>>;
    public apiV2CustomerPut(body?: CustomerPutViewV2, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerResultView>>;
    public apiV2CustomerPut(body?: CustomerPutViewV2, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerResultView>('put',`${this.basePath}/api/v2/Customer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Profile Picture
     * This endpoint allows the customer to delete their profile picture.
     * @param xScheme 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerRemoveProfilePicturePut(xScheme: string, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerWithAdditionalFieldsResultView>;
    public apiV2CustomerRemoveProfilePicturePut(xScheme: string, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerWithAdditionalFieldsResultView>>;
    public apiV2CustomerRemoveProfilePicturePut(xScheme: string, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerWithAdditionalFieldsResultView>>;
    public apiV2CustomerRemoveProfilePicturePut(xScheme: string, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiV2CustomerRemoveProfilePicturePut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomerWithAdditionalFieldsResultView>('put',`${this.basePath}/api/v2/Customer/RemoveProfilePicture`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Suspend Customer
     * This endpoint allows the customer to suspend their account.
     * @param customerId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerSuspendCustomerIdPut(customerId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiV2CustomerSuspendCustomerIdPut(customerId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiV2CustomerSuspendCustomerIdPut(customerId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiV2CustomerSuspendCustomerIdPut(customerId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiV2CustomerSuspendCustomerIdPut.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/v2/Customer/Suspend/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Customer
     * This endpoint allows the customer to delete their account.
     * @param customerId 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CustomerTerminateAccountCustomerIdPost(customerId: string, body?: PasswordView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiV2CustomerTerminateAccountCustomerIdPost(customerId: string, body?: PasswordView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiV2CustomerTerminateAccountCustomerIdPost(customerId: string, body?: PasswordView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiV2CustomerTerminateAccountCustomerIdPost(customerId: string, body?: PasswordView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiV2CustomerTerminateAccountCustomerIdPost.');
        }



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v2/Customer/TerminateAccount/${encodeURIComponent(String(customerId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
