import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PaginatorComponent } from './paginator/paginator.component';
import { ErrorsComponent } from './errors/errors.component';
import { ClipboardModule } from 'ngx-clipboard';
import { MomentModule } from 'ngx-moment';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxBraintreeModule } from 'ngx-braintree';
import { TempTokenComponent } from './temp-token/temp-token.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import {
  OWL_DATE_TIME_FORMATS,
  OwlDateTimeModule,
  OwlMomentDateTimeModule,
  OwlNativeDateTimeModule
} from '@danielmoncada/angular-datetime-picker';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MiniCustomerComponent } from './mini-customer/mini-customer.component';
import { MiniOutletComponent } from './mini-outlet/mini-outlet.component';
import { MiniTransactionComponent } from './mini-transaction/mini-transaction.component';
import { NoResultsComponent } from './no-results/no-results.component';
import { BubbleComponent } from './bubble/bubble.component';
import { DirectivesModule } from '../directives/directives.module';
import { SaveFilterComponent } from './save-filter/save-filter.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { PipesModule } from '../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { OfflineComponent } from './offline/offline.component';
import { OptionsComponent } from './options/options.component';
import { DebugComponent } from './debug/debug.component';
import { CurrentPlanComponent } from './current-plan/current-plan.component';
import { SwitchSchemeComponent } from './switch-scheme/switch-scheme.component';
import { ScheduleActionDialogComponent } from './dialogs/schedule-action-dialog/schedule-action-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { QrcodePrintDialogComponent } from './dialogs/qrcode-print-dialog/qrcode-print-dialog.component';
import { MiniCollectionComponent } from './mini-collection/mini-collection.component';
import { MiniFranchiseComponent } from './mini-franchise/mini-franchise.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { MicroCustomerComponent } from './micro-customer/micro-customer.component';
import { MicroOutletComponent } from './micro-outlet/micro-outlet.component';
import { AppInfoDialogComponent } from './dialogs/app-info-dialog/app-info-dialog.component';
import { ChatButtonComponent } from './chat-button/chat-button.component';
import { PreviewCouponComponent } from './preview-coupon/preview-coupon.component';
import { PreviewEmailComponent } from './preview-email/preview-email.component';
import { PreviewNotificationComponent } from './preview-notification/preview-notification.component';
import { PreviewShopifyComponent } from './preview-shopify/preview-shopify.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MicroTransactionComponent } from './micro-transaction/micro-transaction.component';
import { InfoSheetComponent } from './info-sheet/info-sheet.component';
import { InfoButtonComponent } from './info-button/info-button.component';
import { MicroCouponComponent } from './micro-coupon/micro-coupon.component';
import { MicroGiftCardComponent } from './micro-gift-card/micro-gift-card.component';
import { PreviewGiftCardComponent } from './preview-gift-card/preview-gift-card.component';
import { NestedOutletsComponent } from './nested-outlets/nested-outlets.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { LevelTransferDialogComponent } from './dialogs/level-transfer-dialog/level-transfer-dialog.component';
import { IconLabelComponent } from './icon-label/icon-label.component';
import {
  ActionPushNotificationSelectorComponent
} from './action-selector/action-push-notification-selector/action-push-notification-selector.component';
import { ActionProductSelectorComponent } from './action-selector/action-product-selector/action-product-selector.component';
import { ActionMultiplierSelectorComponent } from './action-selector/action-multiplier-selector/action-multiplier-selector.component';
import { ActionModifierSelectorComponent } from './action-selector/action-modifier-selector/action-modifier-selector.component';
import { ActionGiftcardSelectorComponent } from './action-selector/action-gifcard-selector/action-giftcard-selector.component';
import { ActionAlertSelectorComponent } from './action-selector/action-alert-selector/action-alert-selector.component';
import { ActionEmailSelectorComponent } from './action-selector/action-email-selector/action-email-selector.component';
import { ActionCouponSelectorComponent } from './action-selector/action-coupon-selector/action-coupon-selector.component';
import { IntroComponent } from './intro/intro.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PreviewPostComponent } from './preview-post/preview-post.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { QuillModule } from 'ngx-quill';
import { StepperComponent } from './stepper/stepper/stepper.component';
import { TargetDemographicsSelectorComponent } from './target-demographics-selector/target-demographics-selector.component';
import { TriggerEventComponent } from './trigger-event/trigger-event.component';
import { TriggerTimeComponent } from './trigger-time/trigger-time.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicAndRuleComponent } from './dynamic-and-rule/dynamic-and-rule.component';
import { DynamicOrRuleComponent } from './dynamic-or-rule/dynamic-or-rule.component';
import { ActionCardComponent } from './action-card/action-card.component';
import { AdvancedSchedulingComponent } from './advanced-scheduling/advanced-scheduling.component';
import {
  NewTargetDemographicsDialogComponent
} from './target-demographics-selector/new-target-demographics-dialog/new-target-demographics-dialog';
import {
  NewPushNotificationDialogComponent
} from './action-selector-dialog/new-push-notification-dialog/new-push-notification-dialog.component';
import { NewEmailDialogComponent } from './action-selector-dialog/new-email-dialog/new-email-dialog.component';
import { NewMultiplierDialogComponent } from './action-selector-dialog/new-multiplier-dialog/new-multiplier-dialog.component';
import { NewModifierDialogComponent } from './action-selector-dialog/new-modifier-dialog/new-modifier-dialog.component';
import { NewGiftCardDialogComponent } from './action-selector-dialog/new-giftcard-dialog/new-giftcard-dialog.component';
import { NewCouponDialogComponent } from './action-selector-dialog/new-coupon-dialog/new-coupon-dialog.component';
import { NewAlertDialogComponent } from './action-selector-dialog/new-alert-dialog/new-alert-dialog.component';
import { ActionFeedbackSelectorComponent } from './action-selector/action-feedback-selector/action-feedback-selector.component';
import { NewFeedbackDialogComponent } from './action-selector-dialog/new-feedback-dialog/new-feedback-dialog.component';
import { QuestionSelectorComponent } from './question-selector/question-selector.component';
import { ActionTagSelectorComponent } from './action-selector/action-tag-selector/action-tag-selector.component';
import { NewTagDialogComponent } from './action-selector-dialog/new-tag-dialog/new-tag-dialog.component';
import { ConfirmDeleteDialogComponent } from './dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { LinkPrizeDialogComponent } from './dialogs/link-prize-dialog/link-prize-dialog.component';
import { PreviewSmsComponent } from './preview-sms/preview-sms.component';
import { NewSmsDialogComponent } from './action-selector-dialog/new-sms-dialog/new-sms-dialog.component';
import { ActionSmsSelectorComponent } from './action-selector/action-sms-selector/action-sms-selector.component';
import { ScheduleDialogComponent } from './stepper/schedule-dialog/schedule-dialog.component';
import { PushNotificationComponent } from './main-entities/push-notification/push-notification.component';
import { ScheduleComponent } from './stepper/schedule/schedule.component';
import { SendNowComponent } from './stepper/send-now/send-now.component';
import { EmailComponent } from './main-entities/email/email.component';
import { AlertComponent } from './main-entities/alert/alert.component';
import { CouponComponent } from './main-entities/coupon/coupon.component';
import { SmsComponent } from './main-entities/sms/sms.component';
import { GiftCardComponent } from './main-entities/gift-card/gift-card.component';
import { ExpiryReminderComponent } from './expiry-reminder/expiry-reminder.component';
import { NewEmailEditorComponent } from './email-editor/new-email-editor.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { RedirectConfirmDialogComponent } from './redirect-confirm/redirect-confirm.component';
import { DemoWelcomeDialogComponent } from './dialogs/demo-welcome-dialog/demo-welcome-dialog.component';
import { DemoCtaComponent } from './demo-cta/demo-cta.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { SetupCtaComponent } from './setup-cta/setup-cta.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SetupCardCtaComponent } from './setup-card-cta/setup-card-cta.component';
import { SearchableListComponent } from './searchable-list/searchable-list.component';
import { HealthCtaComponent } from './health-cta/health-cta.component';
import { SubscriptionPlanChangeDialogComponent } from './dialogs/subscription-plan-change-dialog/subscription-plan-change-dialog.component';
import { SubscriptionChangePlanDirective } from './directives/subscription-change-plan.directive';
import { OpenHoursComponent } from './open-hours/open-hours.component';
import { LocalizedDatePipe } from './open-hours/localized-date.pipe';
import { ButtonBackComponent } from './button-back/button-back.component';
import { StatusCtaComponent } from './status-cta/status-cta.component';
import { ImageComponent } from './image/image.component';
import { ImageCropperDialogComponent } from './dialogs/image-cropper-dialog/image-cropper-dialog.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { ReadonlyFieldComponent } from './readonly-field/readonly-field.component';
import { PendingToolbarComponent } from './pending-toolbar/pending-toolbar.component';

export const MY_MOMENT_FORMATS = {
  //parseInput: 'L LT',
  fullPickerInput: 'DD/MM/YYYY LT',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    PaginatorComponent,
    ErrorsComponent,
    TempTokenComponent,
    DateRangePickerComponent,
    DatePickerComponent,
    DateTimePickerComponent,
    TimePickerComponent,
    TextEditorComponent,
    MiniCustomerComponent,
    MiniOutletComponent,
    MiniTransactionComponent,
    NoResultsComponent,
    BubbleComponent,
    SaveFilterComponent,
    OfflineComponent,
    OptionsComponent,
    DebugComponent,
    CurrentPlanComponent,
    SwitchSchemeComponent,
    ScheduleActionDialogComponent,
    ConfirmDialogComponent,
    QrcodePrintDialogComponent,
    MiniCollectionComponent,
    MiniFranchiseComponent,
    GoogleMapComponent,
    MicroCustomerComponent,
    MicroOutletComponent,
    AppInfoDialogComponent,
    ChatButtonComponent,
    PreviewCouponComponent,
    PreviewEmailComponent,
    PreviewNotificationComponent,
    PreviewShopifyComponent,
    SidenavComponent,
    MicroTransactionComponent,
    InfoSheetComponent,
    InfoButtonComponent,
    MicroCouponComponent,
    MicroGiftCardComponent,
    PreviewGiftCardComponent,
    NestedOutletsComponent,
    LevelTransferDialogComponent,
    IconLabelComponent,
    ActionPushNotificationSelectorComponent,
    ActionProductSelectorComponent,
    ActionMultiplierSelectorComponent,
    ActionModifierSelectorComponent,
    ActionGiftcardSelectorComponent,
    ActionEmailSelectorComponent,
    ActionCouponSelectorComponent,
    ActionAlertSelectorComponent,
    IntroComponent,
    SpinnerComponent,
    PreviewPostComponent,
    StepperComponent,
    TargetDemographicsSelectorComponent,
    TriggerEventComponent,
    TriggerTimeComponent,
    DynamicFormComponent,
    DynamicAndRuleComponent,
    DynamicOrRuleComponent,
    ActionCardComponent,
    AdvancedSchedulingComponent,
    NewTargetDemographicsDialogComponent,
    NewPushNotificationDialogComponent,
    NewEmailDialogComponent,
    NewMultiplierDialogComponent,
    NewModifierDialogComponent,
    NewGiftCardDialogComponent,
    NewCouponDialogComponent,
    NewAlertDialogComponent,
    ActionFeedbackSelectorComponent,
    NewFeedbackDialogComponent,
    QuestionSelectorComponent,
    ActionTagSelectorComponent,
    NewTagDialogComponent,
    ConfirmDeleteDialogComponent,
    LinkPrizeDialogComponent,
    PreviewSmsComponent,
    NewSmsDialogComponent,
    ActionSmsSelectorComponent,
    ConfirmDeleteDialogComponent,
    ScheduleDialogComponent,
    PushNotificationComponent,
    ScheduleComponent,
    SendNowComponent,
    EmailComponent,
    AlertComponent,
    CouponComponent,
    SmsComponent,
    GiftCardComponent,
    ExpiryReminderComponent,
    NewEmailEditorComponent,
    SnackBarComponent,
    RedirectConfirmDialogComponent,
    DemoWelcomeDialogComponent,
    DemoCtaComponent,
    SetupCtaComponent,
    SetupCardCtaComponent,
    SearchableListComponent,
    HealthCtaComponent,
    SubscriptionPlanChangeDialogComponent,
    SubscriptionChangePlanDirective,
    OpenHoursComponent,
    LocalizedDatePipe,
    ButtonBackComponent,
    StatusCtaComponent,
    ImageComponent,
    ImageCropperDialogComponent,
    GetStartedComponent,
    ReadonlyFieldComponent,
    PendingToolbarComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    ImageCropperModule,
    ClipboardModule,
    MomentModule,
    ColorPickerModule,
    NgxBraintreeModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    PickerModule,
    PipesModule,
    SimplebarAngularModule,
    TranslateModule,
    CommonModule,
    MaterialModule,
    TranslateModule,
    NgxMatSelectSearchModule,
    MaterialModule,
    FormsModule,
    MomentModule,
    EditorModule,
    QuillModule,
    ClipboardModule,
    TranslateModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    TourMatMenuModule
  ],
  exports: [
    PickerModule,
    SidenavComponent,
    PaginatorComponent,
    TempTokenComponent,
    DateRangePickerComponent,
    DatePickerComponent,
    DateTimePickerComponent,
    TimePickerComponent,
    TextEditorComponent,
    MiniCustomerComponent,
    MicroCustomerComponent,
    MiniOutletComponent,
    MicroOutletComponent,
    MicroTransactionComponent,
    MicroCouponComponent,
    MicroGiftCardComponent,
    MiniTransactionComponent,
    MiniCollectionComponent,
    MiniFranchiseComponent,
    NoResultsComponent,
    BubbleComponent,
    OfflineComponent,
    OptionsComponent,
    DebugComponent,
    CurrentPlanComponent,
    GoogleMapComponent,
    ImageComponent,
    ChatButtonComponent,
    PreviewCouponComponent,
    PreviewGiftCardComponent,
    PreviewEmailComponent,
    PreviewNotificationComponent,
    PreviewShopifyComponent,
    InfoButtonComponent,
    NestedOutletsComponent,
    IconLabelComponent,
    ActionPushNotificationSelectorComponent,
    ActionProductSelectorComponent,
    ActionMultiplierSelectorComponent,
    ActionModifierSelectorComponent,
    ActionGiftcardSelectorComponent,
    ActionEmailSelectorComponent,
    ActionCouponSelectorComponent,
    ActionAlertSelectorComponent,
    IntroComponent,
    SpinnerComponent,
    PreviewPostComponent,
    StepperComponent,
    TargetDemographicsSelectorComponent,
    DynamicFormComponent,
    AdvancedSchedulingComponent,
    NewTargetDemographicsDialogComponent,
    NewPushNotificationDialogComponent,
    NewEmailDialogComponent,
    NewMultiplierDialogComponent,
    NewModifierDialogComponent,
    NewGiftCardDialogComponent,
    NewCouponDialogComponent,
    NewAlertDialogComponent,
    NewTagDialogComponent,
    QuestionSelectorComponent,
    NewFeedbackDialogComponent,
    ActionFeedbackSelectorComponent,
    ActionTagSelectorComponent,
    ConfirmDeleteDialogComponent,
    PreviewSmsComponent,
    NewSmsDialogComponent,
    ActionSmsSelectorComponent,
    ConfirmDeleteDialogComponent,
    PushNotificationComponent,
    EmailComponent,
    ScheduleDialogComponent,
    AlertComponent,
    CouponComponent,
    SmsComponent,
    GiftCardComponent,
    NewEmailEditorComponent,
    RedirectConfirmDialogComponent,
    DemoCtaComponent,
    DemoWelcomeDialogComponent,
    SetupCtaComponent,
    SetupCardCtaComponent,
    SearchableListComponent,
    SubscriptionPlanChangeDialogComponent,
    SubscriptionChangePlanDirective,
    HealthCtaComponent,
    TriggerEventComponent,
    TriggerTimeComponent,
    OpenHoursComponent,
    ButtonBackComponent,
    StatusCtaComponent,
    GetStartedComponent,
    ReadonlyFieldComponent,
    PendingToolbarComponent
  ],
  providers: [
    LocalizedDatePipe,
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }
  ]
})
export class SharedModule { }
