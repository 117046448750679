import { Directive, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionPlanChangeDialogComponent } from '../dialogs/subscription-plan-change-dialog/subscription-plan-change-dialog.component';

@Directive({
  selector: '[subscriptionChangePlanDialogCall]',
  providers: []
})
export class SubscriptionChangePlanDirective {

  constructor(private dialog: MatDialog) {
  }

  @HostListener('click') onClick(): void {
    const dialogRef = this.dialog.open(SubscriptionPlanChangeDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Execute the action when confirmed
      }
    });
  }

}
